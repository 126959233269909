import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { FaArrowDownLong } from "react-icons/fa6";
import MetaData from "../../MetaData";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Modal, ModalGateway } from "react-images";
import Gallery from "react-photo-gallery";
import Carousel from "react-images";
import DP1 from '../../assests/wedding/0078.jpg'
import {getSinglePhoto , deleteSingleLandscape} from "../../action/photo.js";
import DP2 from '../../assests/wedding/081.jpg'
import DP3 from '../../assests/wedding/165.jpg'
import DP4 from '../../assests/wedding/231.jpg'
import DP5 from '../../assests/wedding/JA205518.jpg'
import DP6 from '../../assests/wedding/Legacy+Castle+642.jpg'

import GP1 from '../../assests/group/213.jpg'
import GP2 from '../../assests/group/225.jpg'
import GP3 from '../../assests/group/788.jpg'
import GP4 from '../../assests/group/1667.jpg'
import GP5 from '../../assests/group/Legacy+Castle+614.jpg'


import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import ImageSlider from "./ImageSlider.jsx";
import './projectDisplay.css';

// import required modules
import { FreeMode, Navigation, Thumbs ,Autoplay } from 'swiper/modules';

const image = [
  DP1,
  DP2,
  DP3,
  DP4,
  DP5,
  DP6,
  
];

const gpimages = [
  GP1,
  GP2,
  GP3,
  GP4,
  GP5,
  
  
];

function Work() {
     const [thumbsSwiper, setThumbsSwiper] = useState(null);
     const [isSliderOpen, setIsSliderOpen] = React.useState(false);
     const [landscapeImages, setLandscapeImages] = useState([])
     const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
     const [images, setImages] = useState([])
     const { slug } = useParams();
     const [title, setTitle] = useState("")
     const imageDisplay = (i) =>{
      setTitle(i)
      setIsSliderOpen(true)
     }
     console.log(slug)
     useEffect(() => {
      loadSinglePhoto()
   }, [])
   
   const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  
  console.log(images)

   const loadSinglePhoto = async () => {
      try {
          const singlePhoto = await getSinglePhoto(slug);
  
          if (singlePhoto) {
              // Extract and set landscapeImages and images
              const { project } = singlePhoto;
  
              if (project && Array.isArray(project) && project.length > 0) {
                  setLandscapeImages(project[0].landscapeImages || []);
                  setImages(project[0].images || []);
              }
          }
      } catch (error) {
          console.error(error);
          // Handle error as needed
      }
  };
  
  console.log(images)
  console.log(landscapeImages)
  
  return (
  <>
  <MetaData title="Gallery" />
  <div className='project-display-container'>
    <div className="project-slider">
        <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs , Autoplay]}
        className="mySwiper2"
      > 
        {
            landscapeImages && landscapeImages.length > 0 && landscapeImages.slice().reverse().map(i =>(
                <SwiperSlide className='swiper-main-image' key={i?.Key}>
          <img src={i?.Location} />
        </SwiperSlide>
            ))
        }
        
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="swiper-bottom"
      >
        {
            landscapeImages && landscapeImages.length > 0 && landscapeImages.slice().reverse().map(i =>(
                <SwiperSlide classname="swiper-bottom-part">
          <img src={i?.Location}  />
        </SwiperSlide>
            ))
        }
        
      </Swiper>
    </div>
    <div className="project-photos">
    {
    images && images.length > 0 && (
      <>
            <h1 className='work-title-model' style={{ color: "black", fontSize: "3rem", letterSpacing: "2px" }}>Gallery</h1>
        
      </>
    ) 
    }

  {
    images && images.length > 0 ? (
        <div>
          <Gallery
    className="gallery-photo"
    photos={images && images.length > 0 && images.map(image => ({ src: image?.Location }))}
    onClick={openLightbox}
  />
  <ModalGateway>
    {viewerIsOpen ? (
      <Modal onClose={closeLightbox}>
        <Carousel
          currentIndex={currentImage}
          views={images && images.length > 0 && images.map(image => ({
            src: image?.Location || '', // Ensure src property is set
            srcset: '',
            caption: '',
            regular: '',
          }))}
        />
      </Modal>
    ) : null}
  </ModalGateway>
        </div>
    ) : (
      <div>

      </div>
    )
  }
  
</div>

    {/* <div className="project-display">
    {images && images.length > 0 && images.slice().reverse().map((i, index) => (
                <div className={`cat-${index + 1} cat-links`} key={i?.Key}  style={{cursor : "pointer"}}>
                    <img src={i?.Location} className='project-display-wedding' alt="Wedding" onClick={() => imageDisplay(i?.Location)}/>
                
                </div>
            ))}
    </div>
    {isSliderOpen && <ImageSlider title={title} onClose={() => setIsSliderOpen(false)} />} */}
  </div>
  </>
  )
}

export default Work