import React, { useEffect, useState } from 'react'
import {getAllPhotos} from '../../action/photo.js'
import Navbar from "../Navbar/Navbar.jsx";
import { useHistory } from "react-router-dom";
import './updatephoto.css'
import MetaData from "../../MetaData";
import Menu from "../Menu/Menu.jsx";
import {jwtDecode} from 'jwt-decode';
import { useSelector } from 'react-redux';





function UpdatePhoto() {
  const history = useHistory();
  const [project, setproject] = useState([])
  const [photoCategories, setPhotoCategories] = useState([])
  useEffect(() => {
    loadAllPhotos()
  }, [])
  console.log(project)
  const { auth } = useSelector((state) => ({ ...state }));
  
  useEffect(() => {
    const isAuthInvalid = !auth
  
    if (!isAuthInvalid) {
      const decodedToken = jwtDecode(auth.token);
      const currentTime = Date.now() / 1000; // in seconds
  
      if (decodedToken.exp < currentTime) {
        history.push('/admin');
        return;
      }
    } else {
      history.push('/admin');
    }
  }, [auth]);
  

  const loadAllPhotos = async () => {
      const allPhotos = await getAllPhotos()
      setPhotoCategories(allPhotos)
      const allProjects = allPhotos.map(photo => photo.project);
      setproject(allProjects);
      
  }

  const handleSinglePhoto = (slug) =>{
    history.push(`/dashboard/photo-update/${slug}`)
  }
  console.log(photoCategories)
  return (
    <>
    <MetaData title="Update Photo" />
      <div className='dashboard-container'>
        <div className="dashboard-navbar">
          <Navbar/>
        </div>
        <div className="dashboard-bottom">
          <div className="menu-container">
              <Menu/>
          </div>
          <div className="dashboard-items photo-update">
              <div className="ph-update-category">
                <div className="ph-update-title">
                  <h1>Update Photos</h1>
                </div>
                <div className="ph-photos">
                {project.map((p, index) => (
                    <div className="ph-single-photo" key={index} onClick={()=>handleSinglePhoto(photoCategories[index]?.slug)}>
                      <img src={p[0]?.bannerImage?.Location} alt="" />
                      <h1 className="ph-single-title" style={{color:"white"}}>{photoCategories[index]?.title}</h1>
                    </div>
                  ))}
                </div>
              </div>
          </div>
        </div>
    </div>
    </>
  )
}

export default UpdatePhoto