import React, { useState } from 'react'
import MetaData from "../../MetaData";
import { motion ,  useAnimation} from "framer-motion";
import homevid from '../../assests/4k.mp4'
import { useInView } from "react-intersection-observer";
import service1 from "../../imgs/wedding/1.jpg"
import service2 from "../../imgs/wedding/2.jpg"
import service3 from "../../imgs/wedding/3.jpg"
import { FaArrowRightLong } from "react-icons/fa6";

import service4 from "../../imgs/wedding/4.jpg"
import service5 from "../../imgs/photoshoot/1.jpg"
import service6 from "../../imgs/photoshoot/2.jpg"
import service7 from "../../imgs/photoshoot/3.jpg"
import service8 from "../../imgs/photoshoot/4.jpg"
import service9 from "../../imgs/creative/1.jpg"
import service10 from "../../imgs/creative/2.jpg"
import service11 from "../../imgs/creative/3.jpg"
import service12 from "../../imgs/creative/4.jpg"
import instaIcon from '../../assests/insta-icon.png'
import facebook from '../../assests/facebook.png'
import gmail from '../../assests/gmail.png'
import profile1 from '../../imgs/profile1.jpg'
import profile2 from '../../imgs/profile2.jpg'
import whatsapp from '../../assests/whatsapp.png'
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";

import wedservices1 from '../../assests/wedding1/2.jpg'
import wedservices2 from '../../assests/wedding1/1.jpg'
import wedservices3 from '../../assests/wedding1/3.jpg'
import wedservices4 from '../../assests/wedding1/4.jpg'
import eventservices1 from '../../assests/events/1.jpg'
import eventservices2 from '../../assests/events/2.jpg'
import eventservices3 from '../../assests/events/3.jpg'
import eventservices4 from '../../assests/events/5.jpg'
import creativeservices1 from '../../assests/creative1/2.jpg'

import creativeservices2 from '../../assests/creative1/1.jpg'
import creativeservices3 from '../../assests/creative1/3.jpg'
import creativeservices4 from '../../assests/creative1/4.jpg'
import camera1 from '../../assests/newpic/camera1.png'
import camera3 from '../../assests/newpic/camera3.PNG'
import homevideo from '../../assests/dark.mp4'

import "./home.css"


const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.6,
      delayChildren: 0.5
    }
  }
};

const itemVariants = {
  hidden: {
    opacity: 0,
   
  },
  visible: {
    opacity: 1,
    
    transition: {
      duration: 1
    }
  }
};



function Home() {
  const [textContent, setTextContent] = useState('Photography & Videography Studio');
  const serviceImages1 = [service1, service2, service3, service4]; 
  const wedServices = [wedservices1, wedservices2, wedservices3, wedservices4]; 

  const { ref: service1Ref, inView: service1InView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const handleServiceClick = (title) => {

    const bookingRoute = `/service/${title}`;
   
    window.location.href = bookingRoute;

  
    
  };



  return (
    <>
     <MetaData 
        title="Image Tech Studio | Photography and Videography Studio" 
        description="With more than 10 years of experience in photography and videography, Image Tech Studio creates memorable images that capture the essence of each moment. We specialize in personal portraits, weddings, and event coverage."
      />
    
    <div className='home-main-model'>
        {/* <div className="home-model-forntpage">
            <div className="front-model-left">
              <div className="front-left-inner-div">
          
                  <h1 data-text="back in black">Image Tech Studio</h1>
                  <h2 style={{ letterSpacing: '0px' }} className='interior-text' >
                      {Array.from(textContent).map((char, index) => (
                        
                        <motion.span
                          key={index}
                          initial={{ opacity: 0, y: -20 }}
                          // animate={{ opacity: frontTopInView ? 1 : 0, y: frontTopInView ? 0 : -20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: index * 0.1  }}
                          style={{ display: 'inline-block' }}
                          
                        >
                          {char === ' ' ? '\u00A0' : char}
                        </motion.span>
                      ))}
        
                  </h2>
                  


              </div>

            </div>
                <motion.div
                  className="front-model-right"
                  variants={containerVariants}
                  initial="hidden"
                  animate="visible"
                >
                  <motion.div className="front-grid-div one" variants={itemVariants}></motion.div>
                  <motion.div className="front-grid-div two" variants={itemVariants}></motion.div>
                  <motion.div className="front-grid-div three" variants={itemVariants}></motion.div>
                  <motion.div className="front-grid-div four" variants={itemVariants}></motion.div>
                  <motion.div className="front-grid-div five" variants={itemVariants}></motion.div>
                </motion.div>
        </div> */}
        <div className='background-model'>
        <div class='video-wrap'>
        <video src='https://tactusmarketing.com/wp-content/uploads/tactus-waves-hero.mp4'    autoPlay
                      muted
                      loop
                       id="video-bg">
      

        </video>

{/* <video src={homevideo}    autoPlay
                      muted
                      loop
                       id="video-bg">
      
          
        </video> */}
      </div>

      <div class='content'>
        <h1 class='hero-title blend'>
          Image Tech 
          <br/>
           Studio
        </h1>
        <h2 style={{ letterSpacing: '0px' }} className='interior-text' >
                      {Array.from(textContent).map((char, index) => (
                        
                        <motion.span
                          key={index}
                          initial={{ opacity: 0, y: -20 }}
                          // animate={{ opacity: frontTopInView ? 1 : 0, y: frontTopInView ? 0 : -20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: index * 0.1  }}
                          style={{ display: 'inline-block' }}
                          
                        >
                          {char === ' ' ? '\u00A0' : char}
                        </motion.span>
                      ))}
        
                  </h2>
      </div>
    </div>
        <div className="service-model">
            <div className="title-model">
              <h3>Photography and Videography</h3>
              <section class="wrapperr" style={{textAlign:"none"}}>
                <div class="topr">Services</div>
                <div class="bottomr" aria-hidden="true">Services</div>
              </section>  
            </div>
            <div className="service-div" ref={service1Ref}>
            <div
              className="service-each-div hexagon"
              variants={containerVariants}
              initial="hidden"
              animate={service1InView ? "visible" : "hidden"}
            >
           
                <div className="service-hexagon wedding1">
                      <img src={wedservices1} alt="" />
                    </div>
                    <div className="service-hexagon ">
                      <img src={wedservices2} alt="" />
                    </div>
                    <div className="service-hexagon">
                      <img src={wedservices3}  alt="" />
                    </div>
                    <div className="service-hexagon wedding4">
                      <img src={wedservices4} alt="" />
                    </div>
            </div>
              <div className="service-each-div">
                <h3>01</h3>
                <h1>Wedding Photography & Videography</h1>
                <div className="service-desc">
                  <button className="cta" onClick={() => handleServiceClick("Wedding")}>
                    <span className="hover-underline-animation"> Learn more </span>
                    <FaArrowRightLong className="service-arrow-added" />
                  </button>
                </div>

              </div>
              <div className="service-each-div">
              <h3>02</h3>
                <h1>Events Photography & Videography</h1>
                <div className="service-desc">
                  <button className="cta" onClick={() => handleServiceClick("Studio")}>
                    <span className="hover-underline-animation"> Learn more </span>
                    <FaArrowRightLong className="service-arrow-added" />
                  </button>
                </div>

              </div>
              <div className="service-each-div hexagon1">
                    <div className="service-hexagon event1">
                      <img src={eventservices1} alt="" />
                    </div>
                    <div className="service-hexagon event2">
                      <img src={eventservices2} alt="" />
                    </div>
                    <div className="service-hexagon event3">
                      <img src={eventservices3}  alt="" />
                    </div>
                    <div className="service-hexagon">
                      <img src={eventservices4} alt="" />
                    </div>
              </div>
              <div className="service-each-div hexagon">
                  <div className="service-hexagon">
                      <img src={creativeservices1} alt="" />
                    </div>
                    <div className="service-hexagon">
                      <img src={creativeservices2} alt="" />
                    </div>
                    <div className="service-hexagon">
                      <img src={creativeservices3} alt="" />
                    </div>
                    <div className="service-hexagon">
                      <img src={creativeservices4} alt="" />
                  </div>
              </div>
              <div className="service-each-div">
              <h3>03</h3>
                <h1>Creative Photography & Videography</h1>
                <div className="service-desc">
                  <button className="cta" onClick={() => handleServiceClick("Creative")}>
                    <span className="hover-underline-animation"> Learn more </span>
                    <FaArrowRightLong className="service-arrow-added" />
                  </button>
                </div>
              </div>
            </div>
            
        </div>
        <div className="about-model">
            <div className="title-model">
              <h3>Photographer & Videographer</h3>
              <section class="wrapperr" style={{textAlign:"none"}}>
                <div class="topr">About</div>
                <div class="bottomr" aria-hidden="true">About</div>
              </section>  
            </div>
            <div className="about-model-div">
                <div className="about-model-each-div" style={{color:"white"}}>
                  <figure className="snip1113 red">
                    <img src={profile1} alt="pr-sample1" />
                    <figcaption>
                        <h3>Sarose <span>Maharjan</span></h3>
                        <h4> 
                        Photographer/Instructor
                        </h4>
                    </figcaption>
                    </figure>
                </div>
                <div className="about-model-each-div">
                    
                  <p>
                  With more than <span>10 years</span> of experience in photography and videography, I create memorable images that capture the essence of each moment. I specialize in everything from personal portraits to large events, adapting to your specific needs to ensure your happiness with every photo or video. Count on my professional skills to clearly and creatively bring your vision to life. Let's work together to make your special occasions unforgettable.
                  </p>
                  <h1>𝕾𝖆𝖗𝖔𝖘𝖊 𝕸𝖆𝖍𝖆𝖗𝖏𝖆𝖓</h1>
                  <div className="about-icons">
                    <a href="https://www.instagram.com/hubbyphotographer/" target='_blank' ><FaInstagram className='about-icon-model'/></a>
                    {/* <a href="mailto:moonkiridesign@gmail.com"><img src={gmail} className='GIT-icons'/></a>
                    <a href="whatsapp://send?phone=+9779860124709" ><img src={whatsapp} className='GIT-icons'/></a> */}
                    <a href="https://www.facebook.com/shrek.mhz" target='_blank'><FaFacebook className='about-icon-model'/> </a>
                  </div>
                </div>
                <div className="about-model-each-div" style={{marginTop:"2%"}}>
                  <p>                   
                  With over <span>6 years</span> of experience in photography and videography, I bring a fresh perspective to capturing your important moments. I specialize in personal portraits and event coverage, customizing each shoot to meet your specific needs and ensure you love the results. Trust in my ability to deliver clear, creative images and videos that make your special occasions unforgettable. Let's collaborate to create lasting memories together.

                  </p>
                  <h1>𝕾𝖆𝖓𝖎𝖘𝖍 𝕸𝖆𝖍𝖆𝖗𝖏𝖆𝖓</h1>
                  <div className="about-icons">
                    <a href="https://www.instagram.com/mhzchoxi/" target='_blank' ><FaInstagram className='about-icon-model'/></a>
                    {/* <a href="mailto:moonkiridesign@gmail.com"><img src={gmail} className='GIT-icons'/></a>
                    <a href="whatsapp://send?phone=+9779860124709" ><img src={whatsapp} className='GIT-icons'/></a> */}
                    <a href="https://www.facebook.com/sanish.mhz.1" target='_blank'><FaFacebook className='about-icon-model'/></a>
                  </div>
                </div>
                <div className="about-model-each-div" style={{color:"white" , marginTop:"-2%"}}>
                    <figure className="snip1113" style={{marginTop:"-5%"}}>
                      <img src={profile2} alt="pr-sample1" />
                      <figcaption>
                          <h3>Sanish <span>Maharjan</span></h3>
                          <h4> 
                          Photographer/Videographer
                          </h4>
                      </figcaption>
                      </figure>
                  </div>
            </div>
        </div>
        <div className="contact-studio-model">
          <div className="title-model">
              <h3 style={{marginLeft:"1vw" , fontSize:"3vh"}}>Get in Touch</h3>
              
            </div>
          <div className="contact-bottom">
            
            <div className="contact-blocks first">
                <h3>𝖨𝗆𝖺𝗀𝖾 𝖳𝖾𝖼𝗁 𝖲𝗍𝗎𝖽𝗂𝗈</h3>
              
              <p>Photography and Videography Studio</p>
            </div>
            <div className="contact-blocks">
                <h3>𝖵𝗂𝗌𝗂𝗍 𝖴𝗌</h3>
                <p>Harisiddhi , lalitpur, Nepal</p>
            </div>
            <div className="contact-blocks">
                <h3>𝖢𝗈𝗇𝗍𝖺𝖼𝗍</h3>
                
                <p>imagetech7887@gmail.com</p>
                
            </div>
            <div className="contact-blocks">
              <h3>𝖢𝗈𝗇𝗇𝖾𝖼𝗍 𝖴𝗌</h3>
                <div className="contact-connect-icons">
                {/* <a href="https://www.instagram.com/moonkiri/" target='_blank' ><img src={instaIcon} className='GIT-icons' /></a> */}
                <a href="https://www.facebook.com/profile.php?id=100094303552892" target='_blank'><img src={facebook} className='GIT-icons'/></a>
                <a href="mailto:imagetech7887@gmail.com"><img src={gmail} className='GIT-icons'/></a>
                <a href="whatsapp://send?phone=+977980-8253467" ><img src={whatsapp} className='GIT-icons'/></a>
                </div>
                
            </div>
          </div>
        </div>
    </div>
   
    </>
  )
}

export default Home