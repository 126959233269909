import React from 'react'
import "./footer.css"

function Footer() {
  return (
    <footer class="footer">
			<span>All right reserved 2023 ©</span>
      <span className='design-develop'> 𝕯𝖊𝖘𝖎𝖌𝖓 𝖆𝖓𝖉 𝕯𝖊𝖛𝖊𝖑𝖔𝖕𝖊𝖉 𝖇𝖞 𝕵𝖊𝖊𝖓 𝕸𝖆𝖍𝖆𝖗𝖏𝖆𝖓 </span>
		</footer>
  )
}

export default Footer