import { useEffect, useState } from "react";
import "./booklist.css";
import {Button} from "antd"
// import DataTable from "../../components/dataTable/DataTable";
import { format } from 'date-fns';
import { DataGrid } from "@mui/x-data-grid";
import { products } from "../../data";

import {getAllBookingRecords} from '../../action/book'
import { getPhotoAggregate, getVideoAggregate } from "../../action/video";




const photosCat = [
  {
    field: "title",
    headerName: "Name",
    type: "string",
    width: 150,
  },
  {
    field: "landscapeimage",
    headerName: "Landscape Images",
    type: "number", // Assuming price is a number
    width: 150,
  },
  {
    field: "images",
    headerName: "Images",
    type: "number", // Assuming price is a number
    width: 80,
  },
  

];

const videosCat = [
  {
    field: "title",
    headerName: "Name",
    type: "string",
    width: 150,
  },
  {
    field: "video",
    headerName: "Videos",
    type: "number", // Assuming price is a number
    width: 150,
  },

  

];
const BookList = () => {
  

  const [photoDatas, setPhotoDatas] = useState([])
  const [videoDatas, setVideoDatas] = useState([])

  useEffect(() => {
    
    loadPhotoAggregate()
    loadVideoAggregate()
}, [])

const loadPhotoAggregate = async() =>{

  try {
    const singlePhotoCategory = await getPhotoAggregate()
  
  console.log(singlePhotoCategory)
  const formattedPhotoDatas = singlePhotoCategory.map((record) => ({
    id: record._id,
    title:record.title,
    landscapeimage: record.landscapeImage,
    images: record.images
   
  }));

  setPhotoDatas(formattedPhotoDatas);
  
} catch (error) {
  
}
}


const loadVideoAggregate = async() =>{

  try {
    const singlePhotoCategory = await getVideoAggregate()
  
  console.log(singlePhotoCategory)
  const formattedVideoDatas = singlePhotoCategory.map((record) => ({
    id: record._id,
    title:record.title,
    video: record.video,
   
   
  }));

  setVideoDatas(formattedVideoDatas);
  
} catch (error) {
  
}
}

console.log(videoDatas)



  return (
    <div className="booklist-model">
       <div className="products">
      <div className="info">
        <h1>Photo Records</h1>
        
      </div>
      <DataGrid rows={photoDatas} columns={photosCat} />
      {/* TEST THE API */}

      {/* {isLoading ? (
        "Loading..."
      ) : (
        <DataGrid rows={data} columns={columns} />
      )} */}

      </div>
      <div className="products">
      <div className="info">
        <h1>Video Records</h1>
        
      </div>
      <DataGrid rows={videoDatas} columns={videosCat} />
      {/* TEST THE API */}

      {/* {isLoading ? (
        "Loading..."
      ) : (
        <DataGrid rows={data} columns={columns} />
      )} */}

      </div>
    </div>
  );
};

export default BookList;
