import React, { useEffect, useRef, useState } from 'react'
import {AiFillDelete} from "react-icons/ai"
import { toast } from "react-toastify";
import Navbar from "../Navbar/Navbar.jsx";
import MetaData from "../../MetaData";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { getAllVideoCategory , deleteVideoCategory, submitPhotoCategory, deletePhotoCategory, getPhotoAggregate} from "../../action/video.js"
import { getAllPhotoCategory} from "../../action/photo.js"
import ReactPlayer from "react-player";
import { Button, Input, Select, Tooltip ,Space, Card, Col, Row , Alert, Progress, Modal } from 'antd';
import Menu from "../Menu/Menu.jsx";
import { AiFillCloseCircle } from 'react-icons/ai';

import './photocategory.css'
import {jwtDecode} from 'jwt-decode';
import { useSelector } from 'react-redux';
const { Search } = Input;



function VideoCategory() {
    const history = useHistory();
    const [categoryChanged, setCategoryChanged] = useState("")
    const { auth } = useSelector((state) => ({ ...state }));
    const [categories, setCategories] = useState([])
    const [videoVisible, setVideoVisible] = useState(false);
    const fileVideoRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false); 
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [displayVideo, setDisplayVideo] = useState({})
    const title = categoryChanged

    
    useEffect(() => {
        const isAuthInvalid = !auth
        if (!isAuthInvalid) {
          const decodedToken = jwtDecode(auth.token);
          const currentTime = Date.now() / 1000; // in seconds
      
          if (decodedToken.exp < currentTime) {
            history.push('/admin');
            return;
          }
        } else {
          history.push('/admin');
        }
      }, [auth]);
      

    
useEffect(() => {
    allCategory();
  }, []); 
    console.log(categories)
  
    console.log(categories)

    const allCategory = async () => {
        try {
            const allCategories = await getPhotoAggregate();
            
            setCategories(allCategories)
            
            
        } catch (error) {
            console.error(error);
            // Handle errors here
        }
      };

      console.log(categories)

    const handleSubmit = async () => {
        if (!title) {
            toast.error("Title is required");
            return;
        }
        try {
            const submitCategory = await submitPhotoCategory(title);
            
            if (submitCategory?.message) {
                setCategoryChanged("")
                
                allCategory()
                toast.success(submitCategory.message);

            } else {
                toast.error("Category already exists");
            }
        } catch (error) {
            console.error("Error submitting category:", error);
            toast.error("An error occurred while submitting the category");
        }
    };

    const handleCategoryTtile = (slug) =>{
        history.push(`/dashboard/photo-category/${slug}`)
      }
    

  

    const handleCategoryDelete = async (id) => {
      try {
          const shouldDelete = window.confirm('Are you sure you want to delete this video category?');
  
          if (!shouldDelete) {
              return; // Do nothing if the user cancels the deletion
          }
  
          // Assuming you have access to auth.token here
          const deleteCategory = await deletePhotoCategory(id, auth?.token);
  
          if (deleteCategory && deleteCategory.message) {
              // Show success toast
              toast.success(deleteCategory.message);
              allCategory();
          } else {
              // Show error toast
              toast.error('Failed to delete video category');
          }
      } catch (error) {
          console.error('Error deleting video category:', error);
          // Show error toast
          toast.error('Internal Server Error');
      }
  };

  return (
    <>
    <MetaData title="Photo Category" />
    <div className='dashboard-container'>
        <div className="dashboard-navbar">
            <Navbar/>
        </div>
        <div className="dashboard-bottom">
          <div className="menu-container">
            <Menu/>
          </div>
          <div className="dashboard-items video-category">
                <Space.Compact style={{ width: '100%' }}>
                    <Input placeholder='Create project category' value={categoryChanged} onChange={(e) => setCategoryChanged(e.target.value)}/>
                    <Button type="primary" onClick={handleSubmit}>Submit</Button>
                </Space.Compact>
                
                <div className="dashboard-cat-list">
                    <Row className='dashboard-row' gutter={16}>
                    {
                            categories?.map((category) => (
                                <Col span={7} key={category?._id} className='dash-cat-box'>
                                    <Card title={
                                    <div className='dashboard-cat-heading' >
                                        <span onClick={() => handleCategoryTtile(category.slug)} style={{cursor:"pointer"}}>{category.title}</span>
                                        <AiFillDelete style={{ marginLeft: '8px', cursor: 'pointer' , color:"#720808b7"}} 
                                        onClick={() =>handleCategoryDelete(category?._id)}
                                        />
                                    </div>
                                    } key={category?._id} bordered={false}>
                                    Total {category?.landscapeImage ? category?.landscapeImage: 0} landscape images <br />
                                    Total {category?.images ? category?.images : 0} images
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
          </div>
        </div>
        
    </div>
    </>
  )
}

export default VideoCategory