import React, { useEffect, useRef, useState } from 'react'
import Navbar from "../Navbar/Navbar.jsx";
import Loader from "../../component/Loader/Loader.jsx"
import Menu from "../Menu/Menu.jsx";
import MetaData from "../../MetaData";
import { toast } from "react-toastify";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import {getAllPhotoCategory , saveBannerPhoto  ,removeImage , uploadPhoto, saveLandscapePhoto , savePhoto} from "../../action/photo.js";
import { uploadBannerPhoto} from "../../action/video.js";
import './createphoto.css'
import { Button, Input , Select , Modal } from 'antd';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import {jwtDecode} from 'jwt-decode';
const { Option } = Select;

function CreatePhoto({history}) {
  const { auth } = useSelector((state) => ({ ...state }));
  const [photoCaterories, setPhotoCaterories] = useState([])
  const [photoVisible, setPhotoVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("")
  const [loading, setLoading] = useState(false)
  const fileInputRef = useRef(null);
  const [loader, setloader] = useState(false)
  const [bannerImage, setBannerImage] = useState({})
  const [bannerPhotoVisible, setBannerPhotoVisible] = useState(false);
  const [landscapePhotoVisible, setLandscapePhotoVisible] = useState(false);
  const [landscapeImages, setLandscapeImages] = useState([])
  const [images, setImages] = useState([])
  const slug = selectedCategory;
  console.log(slug)
  

  const handlePhotoButtonClick = () => {
   
    fileInputRef.current.click();
};


useEffect(() => {
  const isAuthInvalid = !auth 

  if (!isAuthInvalid) {
    const decodedToken = jwtDecode(auth.token);
    const currentTime = Date.now() / 1000; // in seconds

    if (decodedToken.exp < currentTime) {
      history.push('/admin');
      return;
    }
  } else {
    history.push('/admin');
  }
}, [auth]);


const handleSaveBanner = async () => {
  try {
    
    console.log('Auth Token:', auth?.token);

    const response = await saveBannerPhoto(bannerImage, slug, auth.token);
    setBannerImage({})
    setBannerPhotoVisible(false)
    toast("banner image saved successfully")

    console.log('Save banner response:', response);
  } catch (error) {
    // Handle error if the save operation fails
    toast.error('Save banner error');
    console.error('Save banner error:', error);
  }
};



const handleSaveLandscape = async () => {
  try {
    console.log('Auth Token:', auth?.token);

    const response = await saveLandscapePhoto(landscapeImages, slug, auth.token);
    
    setLandscapeImages([])
    setLandscapePhotoVisible(false)
    toast("landscape image saved successfully")

   
  } catch (error) {
    // Handle error if the save operation fails
    toast.error('Save landscape image error');
    console.error('Save landscape image error:', error);
  }
};

const handleSaveImages = async () => {
  try {
    

    const response = await savePhoto(images, slug, auth.token);
    
    setImages([])
    setPhotoVisible(false)
    toast("image saved successfully")

   
  } catch (error) {
    // Handle error if the save operation fails
    toast.error('Save Image error');
    console.error('Save Image error:', error);
  }
};




const handleLandscapeImage = async (e) => {
 
  let files = e.target.files;

  try {

    
    setLoading(true)
    console.log('Slug:', slug);
    const response = await uploadPhoto(files , slug);

    // Handle the response from the server if needed
    console.log(response.images);

    // Update the state with the received images
    setLandscapeImages(prevImages => [...prevImages, ...response.images]);
    setLoading(false)
  } catch (error) {
    // Handle error if the upload fails
    setLoading(false)
    console.error('Upload error:', error);
    
  }

}

const handleBannerImage = async (e) => {
  let files = e.target.files;
  console.log(files);

  try {
    setLoading(true)
    const response = await uploadBannerPhoto(files,slug, auth.token);

    // Assuming response.images is the array of uploaded images
    if (response.images && response.images.length > 0) {
      const firstImage = response.images[0];

      // Set the state with relevant information
      setBannerImage({
        Key: firstImage.Key,
        Location: firstImage.Location,
        Bucket: firstImage.Bucket,
        ETag: firstImage.ETag,
        ServerSideEncryption: firstImage.ServerSideEncryption,
        key: firstImage.key,
        // Add other properties as needed
      });
    }
    setLoading(false)

  } catch (error) {
    // Handle error if the upload fails
    setLoading(false)
    console.error('Upload error:', error);
  }
};

const handleImage = async (e) => {
 
  let files = e.target.files;

  try {
    setLoading(true)
    const response = await uploadPhoto(files , slug);

    // Handle the response from the server if needed
    console.log(response.images);

    // Update the state with the received images
    setImages(prevImages => [...prevImages, ...response.images]);
    
    setLoading(false)
  } catch (error) {
    // Handle error if the upload fails
    console.error('Upload error:', error);
    setLoading(false)
  }
};
  console.log(bannerImage)

  useEffect(() => {
    loadAllPhotoCategories()
  }, [])
  
  console.log(photoCaterories)
  const loadAllPhotoCategories = async () => {
    const allPhotoCategories = await getAllPhotoCategory()
    setPhotoCaterories(allPhotoCategories)
  }

  
const handlePhotoDelete = () => async () => {
  try {
    // Call the removeImage action to delete the image on the backend
    setLoading(true)
    await removeImage(bannerImage);

    // Remove the deleted image from the state on the frontend
    setBannerImage({})
    setLoading(false)
  } catch (error) {
    console.error('Error deleting image:', error);
    setLoading(false)
    // Handle error if the image deletion fails
  }
};

const handleLandscapePhotoDelete = (bannerImage) => async () => {
  try {
    // Call the removeImage action to delete the image on the backend
    setLoading(true)
    await removeImage(bannerImage);

    // Remove the deleted image from the state on the frontend
    setLandscapeImages((prevImages) => prevImages.filter((img) => img.Key !== bannerImage.Key));
    setLoading(false)
  } catch (error) {
    console.error('Error deleting image:', error);
    // Handle error if the image deletion fails
    setLoading(false)
  }
};

const handleImageDelete = (image) => async () => {
  try {
    // Call the removeImage action to delete the image on the backend
    setLoading(true)
    await removeImage(image);

    // Remove the deleted image from the state on the frontend
    setImages((prevImages) => prevImages.filter((img) => img.Key !== image.Key));
    setLoading(false)
  } catch (error) {
    console.error('Error deleting image:', error);
    // Handle error if the image deletion fails
    setLoading(false)
  }
};
  
  return (
    <>
    <MetaData title="Create Photo" />
      <div className='dashboard-container'>
        <div className="dashboard-navbar">
          <Navbar/>
        </div>
        <div className="dashboard-bottom">
          <div className="menu-container">
              <Menu/>
          </div>
          <div className="dashboard-items dashboard-photo-create">
            <div className="create-photo-dashboard">
              <div className="create-photo-title">
                <h1>Upload Photos</h1>
              </div>
              <div className="photo-requirement">
                <div className="requirement-tile">
                  <label><span style={{color:"white"}}>Title</span>Title</label>
                  <Select
                  style={{ width: 200 , background:"black"}}
                  placeholder="Select a category"
                  value={selectedCategory}
                  
                  onChange={(value) => setSelectedCategory(value)}
                >
                  {photoCaterories?.map((c) => (
                    <Option key={c?._id} value={c?.slug} style={{color:"black"}}>
                      <p style={{color:"black"}}>{c?.title}</p>
                    </Option>
                  ))}
                </Select>
                </div>
                <Button className='requirement-btn'  onClick={() => setBannerPhotoVisible(true)}><p style={{color:"black"}}>Upload Banner</p></Button>
                <Button className='requirement-btn' onClick={() => setLandscapePhotoVisible(true)}><p style={{color:"black"}}>Upload landscape Photo</p></Button>
                <Button className='requirement-btn' onClick={() => setPhotoVisible(true)}><p style={{color:"black"}}>Upload Photo</p></Button>
              </div>
            </div>
          </div>  
        </div>
        <Modal
              title="Upload Banner"
              centered
              open={bannerPhotoVisible}
              onCancel={() => setBannerPhotoVisible(false)}
              footer={null}
            > 

                {
                  selectedCategory === "" ? (<h1 className='model-title'>Please Select A Photo Title To Upload!!</h1>) : (

                   
                    <div className="uploading-photo">
                
              <div className="uploaded-photo">
              {bannerImage  && loading ? (<Loader/>) : (<div className="image-container" >
                    {
                       (
                        <>
                        {bannerImage?.Location && <img src={bannerImage?.Location} alt="" />}
                      <AiFillCloseCircle className='photo-close' onClick={handlePhotoDelete()} />
                        </>)
                    }
                      
                  </div>)
                  
             }
              </div>

                <div className="upload-button">
                {Object.keys(bannerImage).length === 0 ? (
        <Button
          style={{
            padding: '10px 50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          disabled={loading}
          onClick={handlePhotoButtonClick}
        >
           <p style={{color:"black"}}>Upload Photo</p>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleBannerImage}
            accept="image/*"
            style={{ display: 'none' }}
          />
        </Button>
      ) : (
        <Button
          style={{
            padding: '10px 50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={handleSaveBanner}
        >
           <p style={{color:"black"}}>Save</p>
        </Button>
      )}
                  </div>
              </div>
                  )
                }
              
            </Modal>
            <Modal
              title="Upload Landscape Photos"
              className='image-container'
              centered
              open={landscapePhotoVisible}
              onCancel={() => setLandscapePhotoVisible(false)}
              footer={null}
            > 
            {
                  selectedCategory === "" ? (<h1 className='model-title'>Please Select A Photo Title To Upload!!</h1>) : (<div className="uploading-photo">
                  <div className="uploaded-photo">
                  {landscapeImages && loading ? (<Loader/>) : (landscapeImages.map((i) => (
                      <div className="image-container" key={i?.Key}>
                        {
                         (
                            <>
                            <img src={i?.Location} alt="" />
                          <AiFillCloseCircle className='photo-close' onClick={handleLandscapePhotoDelete(i)} />
                            </>)
                        }
                          
                      </div>
                  ))) }
                  </div>
    
                    <div className="upload-button">
          
            <Button
              style={{
                padding: '10px 50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={handlePhotoButtonClick}
              disabled={loading}
            >
              <p style={{color:"black"}}>Upload Photo </p>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleLandscapeImage}
                accept="image/*"
                style={{ display: 'none' }}
                multiple
              />
            </Button>
        
           {landscapeImages.length !== 0 && <Button
              style={{
                padding: '10px 50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={handleSaveLandscape}
            >
               <p style={{color:"black"}}>Save</p>
            </Button>}
         
                      </div>
                  </div>)}
              
            </Modal>
            <Modal
              title="Add Images"
              centered
              open={photoVisible}
              onCancel={() => setPhotoVisible(false)}
              footer={null}
            > 
            {
                  selectedCategory === "" ? (<h1 className='model-title' style={{color:"black"}}>Please Select A Photo Title To Upload!!</h1>) : (
                    <div className="uploading-photo">
              <div className="uploaded-photo">
              {images && loading ? (<Loader/>) : (images.map((i) => (
                  <div className="image-container" key={i?.Key}>
                    {
                     (
                        <>
                        <img src={i?.Location} alt="" />
                      <AiFillCloseCircle className='photo-close' onClick={handleImageDelete(i)} />
                        </>)
                    }
                      
                  </div>
              ))) }
              </div>

                  <div className="upload-button">
                  <Button style={{ padding: "10px 50px", display: "flex", alignItems: "center", justifyContent: "center" }} 
                  onClick={handlePhotoButtonClick} 
                  disabled={loading}>
                     <p style={{color:"black"}}>Upload Photo</p>
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleImage}
                        accept="image/*"
                        multiple 
                        style={{ display: "none" }}
                    />
                  </Button>
                  {images.length !== 0 && <Button
          style={{
            padding: '10px 50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={handleSaveImages}
        >
           <p style={{color:"black"}}>Save</p>
        </Button>}
                  </div>
              </div>
                  )}
              
            </Modal>
    </div>
    </>
  )
}

export default CreatePhoto