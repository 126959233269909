import React, { useEffect, useState } from 'react'
import { getAllVideoCategory , deleteIndividualProject} from "../../action/video.js"
import { motion } from "framer-motion";
import { Button, Select, Tooltip } from "antd";
import { MdDeleteForever } from "react-icons/md";
import Navbar from "../Navbar/Navbar.jsx";
import "./updateVideo.css"
import MetaData from "../../MetaData";
import Menu from "../Menu/Menu.jsx";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {jwtDecode} from 'jwt-decode';






function UpdateVideo({history}) {
  const [categories, setCategories] = useState([])
  const [activeCategory, setActiveCategory] = useState(0);
  const { auth } = useSelector((state) => ({ ...state }));
  useEffect(() => {
    allCategory();
  }, []); 
  
  const slug = categories[activeCategory]?.slug

useEffect(() => {
  const isAuthInvalid = !auth

  if (!isAuthInvalid) {
    const decodedToken = jwtDecode(auth.token);
    const currentTime = Date.now() / 1000; // in seconds

    if (decodedToken.exp < currentTime) {
      history.push('/admin');
      return;
    }
  } else {
    history.push('/admin');
  }
}, [auth]);


  const allCategory = async () => {
    try {
        const allCategories = await getAllVideoCategory();
        
        setCategories(allCategories)
        
        
    } catch (error) {
        console.error(error);
        // Handle errors here
    }
  };
  console.log(auth.token)

  const handleProjectDelete = async (id) => {
    // Show a confirmation dialog
    const confirmed = window.confirm('Are you sure you want to delete this project?');
    
    // If the user confirmed, proceed with the deletion
    if (confirmed) {
      const deleteIndividualVideo = await deleteIndividualProject(slug, id, auth?.token);
      allCategory()
      toast("video Delete successfully")
      console.log(slug, id);
    } else {
      // Handle the case where the user canceled the deletion
      console.log('Deletion canceled');
      toast.error("Deletion Failed")
    }
  };

  const handleCategoryClick = (e, index) => {
    e.preventDefault();
    setActiveCategory(index);
    // setIsCategoryClicked(true);
  };
  console.log(categories)
  return (
    <>
    <MetaData title="Update Video" />
      <div className='dashboard-container'>
        <div className="dashboard-navbar">
          <Navbar/>
        </div>
        <div className="dashboard-bottom">
          <div className="menu-container">
              <Menu/>
          </div>
          <div className="dashboard-items update-video">
          <div className="project-categories">
            {categories?.map((category, index) => (
                <div>
                  <motion.a
                      href="#"
                      className={index === activeCategory ? 'active' : ''}
                      onClick={(e) => {handleCategoryClick(e, index); console.log(index)}}
                      
                        animate={index === activeCategory  ? 'visible' : 'hidden'}
                    variants={{
                      visible: {
                        opacity: 1,
                        y:0 ,
                        
                        transition: {
                          ease: 'backIn',
                          duration: .5,
                        },
                      },
                      hidden: {
                        opacity: 0.3,
                        y:10
                        
                      },
                    }} 
                    >
                      {category?.title}
                  </motion.a>
                </div>
                
              ))}
              
            </div>
            <div className="project-main">
              {

                    categories[activeCategory]?.project?.map((project, index) => (
                      <div>
                      <motion.div
                        className="box viewproject"
                        key={index}
                        initial="hidden"
                        animate= "visible" 
                        variants={{
                          visible: {
                            opacity: 1,
                            y: 0,
                            x:0,
                            transition: {
                              ease: 'backIn',
                              duration: 0.5,
                            },
                          },
                          hidden: {
                            opacity: .5,
                            y: 0,
                            x:-50
                          },
                        }}
                      > 
                        <Tooltip title="Delete project">

                        <MdDeleteForever className='project-deletion' onClick={() => handleProjectDelete(project._id )}/>
                        </Tooltip>
                        <motion.img
                          key={project?.bannerImage?.Key}
                          whileHover={{ scale: 1.1, transition: { ease: 'circIn', duration: 0.5 } }}
                          src={project?.bannerImage?.Location}
                          alt=""
                          // onClick={() => handleProjectDisplay(project?.slug)}
                        />
                      </motion.div>
                        <h1 className='individual-video-title'>{project?.videoTitle}</h1>
                      </div>
                      
                    ))
                  }
              </div>
          </div>
        </div>
    </div>
    </>
  )
}

export default UpdateVideo