import React from 'react';
import './businesscard.css';
import businesslogo from '../../imgs/logo1.svg'

function BusinessCard() {
  return (
    <div className='business-card-model'>
      <div className="flip-container centered">
        <div className="flipper">
          <div className="front">
           
          </div>
          <div className="back">
            

          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessCard;
