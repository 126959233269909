import React, { useState } from 'react'
// import MetaData from "../../MetaData";
import "./contact.css"
import { FaArrowDownLong } from "react-icons/fa6";
import MetaData from "../../MetaData";
import { toast } from 'react-toastify';
import front from '../../assests/newpic/front.png'
import back from '../../assests/newpic/back.png'
import { FaLocationDot } from "react-icons/fa6";
import { BsFillTelephoneFill } from "react-icons/bs";
import { Button } from 'antd';

function Contact() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [desc, setDesc] = useState("")




  return (
    <>
    <MetaData title="Contact Us" />
    <div className='contact-container'>
        <div className="booking-title">
        <div class="text-boxx">
		<h1 style={{fontSize:"8vh"}}>Contact Us</h1>
		<h1 style={{fontSize:"8vh"}}>Contact Us</h1>
	</div>
        </div>
        <div className="contact-middle">
            <div className="contact-middle-left">
              <h1> Leave Us a Message  </h1>
              <p>Feel free to reach out to us for inquiries, collaborations, or just to say hello – we'd love to hear from you!</p>
              <div className="contact-icons">
                <div className="contact-icon-deco">
                  <FaLocationDot className='actual-icon'/>
                </div>
                <p className='icon-desc' style={{letterSpacing:"5px"}}>Harisiddhi, Lalitpur, Nepal</p>
              </div>
              <div className="contact-icons">
                <div className="contact-icon-deco">
                  <BsFillTelephoneFill className='actual-icon'/>
                </div>
                <p className='icon-desc' style={{letterSpacing:"5px"}}>+977-9801912249</p>
                
              </div>
             
            </div>
            <div className="contact-middle-right">
            <div className="flip-container centered">
        <div className="flipper">
          <div className="front">
            <img src={front} alt="" />
          </div>
          <div className="back">
            
            <img src={back} alt="" />
          </div>
        </div>
      </div>
            </div>
        </div>
        <div style={{ width: '100%' }} className='contact-map'>
        <iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Image%20Tech%20Studio%20lalitpur,%20Nepal+(Image%20tech%20studio)&amp;t=k&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps trackers</a></iframe>
        </div>
       
    </div>
    </>
  )
}

export default Contact