import { useEffect, useState } from 'react';
import logo from '../../assests/logo2.svg'
import logo1 from '../../imgs/logo1.svg'

import { FaArrowDownLong } from "react-icons/fa6";
import { motion, useAnimation } from 'framer-motion';
import { CiMenuBurger } from "react-icons/ci";
import { MdOutlineClose } from "react-icons/md";
import { useInView } from "react-intersection-observer";
import { Link as ScrollLink, animateScroll } from 'react-scroll';
import { useHistory } from "react-router-dom";
import './header1.css'
import { AllPhotoCategory  } from '../../action/photo';
import { AllVideoCategory } from '../../action/video';

function Headers() {
  const history = useHistory();
  const pathname = history.location.pathname;
  const [categories, setCategories] = useState([])
  const [videoCategories, setVideoCategories] = useState([])
  const [isNavActive, setIsNavActive] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showNav, setShowNav] = useState(false)
  const [resProduct, setResProduct] = useState(false)
  const [resVideo, setResVideo] = useState(false)

  const [logoActive, setLogoActive] = useState(false);

  const controls = useAnimation();

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

   const handleLogoClick = () => {

    history.push("/")
    setLogoActive(false)
  };

  const handleContact = () =>{
    const bookingRoute = `/contact`;
   
    window.location.href = bookingRoute;
    
    setShowNav(!showNav);
    setLogoActive(false)
  }

  const handleProductsHover = () => {
    setIsDropdownOpen(true);
  };

  const handleProjectDisplay = (title) => {

    const bookingRoute = `/work/${title}`;
   
    window.location.href = bookingRoute;

    setResProduct(false)
    setShowNav(!showNav);
    
  };
  const handleNavLinkClick = () => {
    history.push("/")
    setShowNav(false);
    setLogoActive(false)
  };
  
  const handleProductsLeave = () => {
    setIsDropdownOpen(false);
  };



  useEffect(() => {
    const handleScroll = () => {
      const scrollDock = window.scrollY;

      if (scrollDock > 1) {
        setIsNavActive(true);
      } else {
        setIsNavActive(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleVideoDisplay = (title) => {

    const bookingRoute = `/videos/${title}`;
   
    window.location.href = bookingRoute;

    setResProduct(false)
    setResVideo(false)  
    setShowNav(!showNav);
    setLogoActive(false)
    
  };

    const handleResProductClick = () =>{
      setResProduct(!resProduct)
    }

   
  
      const handleResVideoClick = () =>{
        setResVideo(!resVideo)
      }
  
      useEffect(() => {
        loadAllPhotoCategories();
        loadAllVideoCategories();
      }, []); 
  
      const loadAllPhotoCategories = async () => {
        const allPhotos = await AllPhotoCategory()
        setCategories(allPhotos)
     
    }
  
    const loadAllVideoCategories = async () => {
      const allVideos = await AllVideoCategory()
      setVideoCategories(allVideos)
   
  }
  
  

  return (
    <>
      <div className={`nav ${isNavActive ? 'active' : ''}`}>
      <div className="header-left" onClick={()=> handleLogoClick()}>
        <img
            src={logo}
            className={`header-logo`}
            
            alt="" /> 
   
           

      </div>
      <div className="header-right">
        <ScrollLink
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                
                style={{cursor : "pointer"}}
                activeClass={pathname === '/' ? 'active-link' : ''}
                
                onClick={()=> handleLogoClick()} className={`navLinks ${isNavActive ? 'active' : ''}`}>Home</ScrollLink>
                  <div
          className={`navLinks ${isNavActive ? 'active' : ''} product`}
          onMouseEnter={handleProductsHover}
          onMouseLeave={handleProductsLeave}
        >
          Gallery 
          {isDropdownOpen && (
            <div className="dropdown-content">
              {categories.map((c) => (
                 <a href="#" onClick={() =>{handleProjectDisplay(c?.slug)}}>{c?.title}</a>
              ))}
              
              {/* Add more items as needed */}
            </div>
          )}
        </div>
        <div
          className={`navLinks ${isNavActive ? 'active' : ''} product`}
          onMouseEnter={handleProductsHover}
          onMouseLeave={handleProductsLeave}
        >
          Videos 
          {isDropdownOpen && (
            <div className="dropdown-content">
              {videoCategories.map((c) => (
                 <a href="#" onClick={() =>{handleVideoDisplay(c?.slug)}}>{c?.title}</a>
              ))}
              
              {/* Add more items as needed */}
            </div>
          )}
        </div>

      
       
        <a href="#" className={`navLinks ${isNavActive ? 'active' : ''}` } onClick={()=> handleContact()}>Contact</a>
        <CiMenuBurger  className={`sub-menu ${isNavActive ? 'active' : ''}` } onClick={() => {setShowNav(!showNav) ; setLogoActive(!logoActive)} }/>
      </div>
     
    </div>
      
    <div  className={`responsive-nav ${showNav ? 'active' : ''} ${inView ? 'visible' : ''}`} >
    <MdOutlineClose className='res-close' onClick={() => {setShowNav(!showNav)} }/>
    
       <ScrollLink
          to='home'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={handleNavLinkClick}
        >
          Home
        </ScrollLink>
        <div
          className={`res-navLinks`}
          onClick={() => handleResProductClick()}
        >
          Gallery <FaArrowDownLong className='header-down-arrow'/> 
          {resProduct && (
            <div className="res-dropdown-content">
              {categories.map((c) => (
                 <a href="#" onClick={() =>{handleProjectDisplay(c?.slug)}}>{c?.title}</a>
              ))}
              
             
              {/* Add more items as needed */}
            </div>
          )}
        </div>
        <div
          className={`res-navLinks`}
          onClick={() => handleResVideoClick()}
        >
          Videos <FaArrowDownLong className='header-down-arrow'/> 
          {resVideo && (
            <div className="res-dropdown-content">
              {videoCategories.map((c) => (
                 <a href="#" onClick={() =>{handleVideoDisplay(c?.slug)}}>{c?.title}</a>
              ))}
              
             
              {/* Add more items as needed */}
            </div>
          )}
        </div>
        <ScrollLink
          to='team'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={()=> handleContact()}
        >
          Contact
        </ScrollLink>
        
      
     
     
  </div> 
 

    </>
    
  )
}

export default Headers