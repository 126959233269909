import React, { useEffect, useRef, useState } from 'react'
import { getAllVideoCategory, postVideo , getSingleVideoCategory , updateVideoCategory} from "../../action/video.js"
import Navbar from "../Navbar/Navbar.jsx";
import { useParams } from 'react-router-dom';
import ReactPlayer from "react-player";
import { Button, Input, Select, Tooltip ,Space, Card, Col, Row , Alert, Progress, Modal } from 'antd';
import Menu from "../Menu/Menu.jsx";
import { toast } from "react-toastify";
import { AiFillCloseCircle } from 'react-icons/ai';
import { Checkbox } from 'antd';
import axios from "axios";
import MetaData from "../../MetaData";
import "./updateVideoTitle.css"
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import {jwtDecode} from 'jwt-decode';
const { Option } = Select;  



function UpdateVideoTitle() {
  const [title, setTitle] = useState("")
  const history = useHistory();
  const [pin, setPin] = useState(false)
  const fileVideoRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [videoVisible, setVideoVisible] = useState(false);
  const { auth } = useSelector((state) => ({ ...state }));
  const [isLoading, setIsLoading] = useState(false); 
  const [uploading, setUploading] = useState(false);
  const [displayVideo, setDisplayVideo] = useState({})
  const { slug } = useParams();

  useEffect(() => {
    loadSingleVideoCategory()
  }, [])

  
  useEffect(() => {
    const isAuthInvalid = !auth
  
    if (!isAuthInvalid) {
      const decodedToken = jwtDecode(auth.token);
      const currentTime = Date.now() / 1000; // in seconds
  
      if (decodedToken.exp < currentTime) {
        history.push('/admin');
        return;
      }
    } else {
      history.push('/admin');
    }
  }, [auth]);
  
  

  const loadSingleVideoCategory = async() =>{
    const singleVideoCategory = await getSingleVideoCategory(slug)
    console.log(singleVideoCategory)
    setPin(singleVideoCategory.pin)
    setDisplayVideo(singleVideoCategory.displayVideo)
    setTitle(singleVideoCategory.title)
  }

  const handleVideoButtonClick = () => {
   
    fileVideoRef.current.click();
  };

  const handleVideo = async (e) => {
    try {
        setIsLoading(true)
        const file = e.target.files[0];
    
     
     
        setUploading(true);
    
        const videoData = new FormData();
        videoData.append("video", file);
        // save progress bar and send video as form data to backend
        try {
          const { data } = await axios.post(
            `/api/project/upload-video/${title}`,
            videoData,
            {
              onUploadProgress: (e) => {
                setProgress(Math.round((100 * e.loaded) / e.total));
              },
            }
          );
          console.log(data);
        setDisplayVideo(data)
        setUploading(false);
        toast("Video Uploaded");
        } catch (error) {
          toast.error(error)
        }
        setIsLoading(false)
        
        
      } catch (err) {
        console.log(err);
        setUploading(false);
        setIsLoading(false)
        toast("Video upload failed");
      }

    
}
const handleVideoRemove = async () => {
  try {
      setUploading(true);
      const { data } = await axios.post(
        `/api/project/remove-video`,
        displayVideo
      );
      console.log(data);
      setDisplayVideo({});
      setUploading(false);
      toast("Video Removed")
    } catch (err) {
      console.log(err);
      setUploading(false);
      toast("Video remove failed");
    }
}

const handleVideoSave = async () => {

 
  if (!displayVideo || Object.keys(displayVideo).length === 0) {
    toast.error('Please upload a video');
    return;
  }

  if (!title) {
    toast.error('Please enter a video title');
    return;
  }
  try {
      // Call your updateVideoCategory function
      const result = await updateVideoCategory(slug, title, displayVideo, pin, auth?.token);

      // Check if the update was successful
      if (result && result.message) {
          
          toast.success(result.message);
          history.push("/dashboard/video-category")
      } else {
          // Show error toast
          toast.error('Failed to update video category');
      }
  } catch (error) {
      console.error('Error updating video category:', error);
      // Show error toast
      toast.error('Internal Server Error');
  }
};


const onChange = (e) => {
  console.log(`checked = ${e.target.checked}`);
  setPin(e.target.checked)
};


  return (
   <>
   <MetaData title="Update Video" />
    <div className='dashboard-container'>
        <div className="dashboard-navbar">
          <Navbar/>
        </div>
        <div className="dashboard-bottom">
          <div className="menu-container">
              <Menu/>
          </div>
          <div className="dashboard-items createVid">
          <div className="create-photo-dashboard">
              <div className="create-photo-title">
                <h1>Upload Video</h1>
              </div>
              <div className="photo-requirement">
                <div className="requirement-tile crt-vid">
                  
                  <div className="create-vid-title">
                    <label>Title</label>
                    <Input className='photos-title-input' value={title} onChange={(e) => setTitle(e.target.value)}/>
                  </div>
                </div>
                <Checkbox checked={pin} onChange={onChange}><p className='dashboard-model-title' style={{style:"white"}}>Pin</p> </Checkbox>
                <Button className='requirement-btn' onClick={() => setVideoVisible(true)}>Upload Video</Button>
                <Button className='requirement-btn' onClick={() => handleVideoSave()}>Save Video</Button>
              </div>
            </div>
          </div>
        </div>
        <Modal
              title="Add Project Video"
              centered
              open={videoVisible}
              onCancel={() => setVideoVisible(false)}
              footer={null}
            >
               <div className="uploading-video">
                <div className="upload-video">
                {!uploading && displayVideo?.Location && (
                  <>
                  <ReactPlayer
                  url={displayVideo?.Location}
                  width="410px"
                  height="240px"
                  controls
                  />
                  <Tooltip title="Remove Video" >
                  <AiFillCloseCircle className='video-remove' onClick={handleVideoRemove}/>

                  </Tooltip></>
                )}
                   {progress > 0 && uploading &&(
                      <Progress
                        className="d-flex justify-content-center pt-2"
                        percent={progress}
                        steps={10}
                      />
                    )}
                </div>
                <div className="upload-video-button">
                    {
                        Object.keys(displayVideo).length === 0 ? (<Button style={{ padding: "10px 50px", display: "flex", alignItems: "center", justifyContent: "center" }} 
                        onClick={handleVideoButtonClick}  disabled={isLoading}>
                          Upload Video
                          <input
                              type="file"
                              ref={fileVideoRef}
                              onChange={handleVideo}
                              accept="video/*"
                              style={{ display: "none" }}
                          />
                          </Button>) : (<div>

                          </div>)
                    }
                  
                </div>
               </div>
            </Modal>
    </div>
   </>
  )
}

export default UpdateVideoTitle