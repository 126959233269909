import React, { useEffect } from 'react'
import './dasboard.css'
import MetaData from "../../MetaData";
import Navbar from "../Navbar/Navbar.jsx";
import BookList from "../BookList/BookList.jsx";
import Menu from "../Menu/Menu.jsx";
import { Table, Tag, Space } from 'antd';
import { useSelector } from 'react-redux';




function Dashboard({history}) {
  const { auth } = useSelector((state) => ({ ...state }));
  useEffect(() => {
    if (auth === null) history.push("/admin");
  }, [auth]);

  return (
    <>
    <MetaData title="Dashboard" />
      <div className='dashboard-container'>
        <div className="dashboard-navbar">
          <Navbar/>
        </div>
        <div className="dashboard-bottom">
          <div className="menu-container">
              <Menu/>
          </div>
          <div className="dashboard-items">
            <BookList/>
          </div>
        </div>
    </div>
    </>
  )
}

export default Dashboard