import { BrowserRouter as Router, Route, Switch , useLocation  } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./component/Home/Home.jsx";
import Footer from "./component/Footer/Footer.jsx"
import Team from "./component/Team/Team.jsx"
import Contact from "./component/Contact/Contact.jsx"
import Dashboard from "./component/Dashboard/Dashboard.jsx";
import PageNotfound from "./component/PageNotfound/PageNotfound.jsx"
import UpdateSinglePhoto from "./component/UpdateSinglePhoto/UpdateSinglePhoto.jsx";
import UpdateVideoTitle from "./component/UpdateVideoTitle/UpdateVideoTitle.jsx";
import UpdatePhotoTitle from "./component/UpdatePhotoTitle/UpdatePhotoTitle.jsx";
import ChangePassword from "./component/ChangePassword/ChangePassword.jsx";
import Admin from "./component/Admin/Admin.jsx";
import CreatePhoto from "./component/CreatePhoto/CreatePhoto.jsx";
import BusinessCard from "./component/BusinessCard/BusinessCard.jsx";
import PaymentSuccess from "./component/PaymentSuccess/PaymentSuccess.jsx";
import UpdatePhoto from "./component/UpdatePhoto/UpdatePhoto.jsx";
import CreateVideo from "./component/CreateVideo/CreateVideo.jsx";
import UpdateVideo from "./component/UpdateVideo/UpdateVideo.jsx";
import VideoCategory from "./component/VideoCategory/VideoCategory.jsx";
import PhotoCategory from "./component/PhotoCategory/PhotoCategory.jsx";
import Booking from "./component/Booking/Booking.jsx"
import Videos from "./component/Videos/Videos.jsx"
import Work from "./component/Work/Work.jsx"
import Background from "./component/Background/Background.jsx"
import Services from "./component/Services/Services.jsx"
import StudioServices from "./component/StudioServices/StudioServices.jsx"
import CreativeServices from "./component/CreativeServices/CreativeServices.jsx"
import Demo from "./component/Demo/Demo.jsx"
import { useEffect, useState } from "react";
import Headers from "./component/Headers/Header1.jsx"
import TawkTo from "./component/TawkTo/TawkTo.jsx";

import { ReactLenis } from '@studio-freight/react-lenis';
const lenisOptions = {
  lerp: 0.07, // Increase this value for smoother and slower scrolling
  smooth: true,
  direction: 'vertical'
};

function Layout() {
  const location = useLocation(); // Hook to get the current location
  const showHeaderAndFooter = !(
   
    location.pathname === "/changepassword" ||
    location.pathname === "/admin" ||
    location.pathname === "/dashboard" ||
    location.pathname === "/dashboard/viewproject" ||
    location.pathname.startsWith("/dashboard/")
  );
  

  return (
    <ReactLenis root={true} autoRaf={true} options={lenisOptions}>
       {showHeaderAndFooter  && <Headers />}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/booking" component={Booking} />
        <Route exact path="/work/:slug" component={Work} />
        <Route exact path="/service/wedding" component={Services} />
        <Route exact path="/service/studio" component={StudioServices} />
        <Route exact path="/service/creative" component={CreativeServices} />
        <Route exact path="/demo" component={Demo} />
        <Route exact path="/team" component={Team} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/videos/:slug" component={Videos} />
        <Route exact path="/payment-success" component={PaymentSuccess} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/admin" component={Admin} />
        <Route exact path="/background" component={Background} />
        <Route exact path="/businesscard" component={BusinessCard} />
        <Route exact path="/changepassword" component={ChangePassword} />
        <Route exact path="/dashboard/photo-create" component={CreatePhoto} />
        <Route exact path="/dashboard/photo-update" component={UpdatePhoto} />
        <Route exact path="/dashboard/photo-update/:slug" component={UpdateSinglePhoto} />
        <Route exact path="/dashboard/video-category/:slug" component={UpdateVideoTitle} />
        <Route exact path="/dashboard/photo-category/:slug" component={UpdatePhotoTitle} />
        <Route exact path="/dashboard/video-create" component={CreateVideo} />
        <Route exact path="/dashboard/video-update" component={UpdateVideo} />
        <Route exact path="/dashboard/video-category" component={VideoCategory} />
        <Route exact path="/dashboard/photo-category" component={PhotoCategory} />
        
         <Route component={PageNotfound} />
      </Switch>
      <Footer />
    </ReactLenis>
  );
}

function App() {
  const [loadingg, setloadingg] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setloadingg(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <ToastContainer position="top-center" />
     
     <Layout />
    </Router>
  );
}

export default App;
