import React from 'react'
import './background.css'

function Background() {
  return (
    <div className='background-model'>
   
    </div>
  )
}

export default Background