import React, { useEffect, useRef, useState } from 'react'
import Navbar from "../Navbar/Navbar.jsx";
import Loader from "../../component/Loader/Loader.jsx"
import { useParams } from 'react-router-dom';
import Menu from "../Menu/Menu.jsx";
import { toast } from "react-toastify";
import {getSinglePhoto , deleteSingleImage} from "../../action/photo.js";
import {  deleteSingleLandscape} from "../../action/video.js";
import MetaData from "../../MetaData";
import { Button, Input , Select , Modal } from 'antd';
import { AiFillCloseCircle } from 'react-icons/ai';
import {jwtDecode} from 'jwt-decode';
import { useSelector } from 'react-redux';
const { Option } = Select;

function UpdateSinglePhoto({history}) {
  const { auth } = useSelector((state) => ({ ...state }));
  const { slug } = useParams();
  const [photoVisible, setPhotoVisible] = useState(false);
 
  const [loading, setLoading] = useState(false)

  const [landscapePhotoVisible, setLandscapePhotoVisible] = useState(false);
  const [landscapeImages, setLandscapeImages] = useState([])
  const [images, setImages] = useState([])


  useEffect(() => {
    const isAuthInvalid = !auth
  
    if (!isAuthInvalid) {
      const decodedToken = jwtDecode(auth.token);
      const currentTime = Date.now() / 1000; // in seconds
  
      if (decodedToken.exp < currentTime) {
        history.push('/admin');
        return;
      }
    } else {
      history.push('/admin');
    }
  }, [auth]);
  

 console.log(slug)

 
  useEffect(() => {
    if (auth === null) history.push("/admin");
  }, [auth]);

 useEffect(() => {
    loadSinglePhoto()
 }, [])
 
 

 const loadSinglePhoto = async () => {
    try {
        const singlePhoto = await getSinglePhoto(slug);

        if (singlePhoto) {
            // Extract and set landscapeImages and images
            const { project } = singlePhoto;

            if (project && Array.isArray(project) && project.length > 0) {
                setLandscapeImages(project[0].landscapeImages || []);
                setImages(project[0].images || []);
            }
        }
    } catch (error) {
        console.error(error);
        // Handle error as needed
    }
};

console.log(images)
console.log(landscapeImages)



const handleLandscapePhotoDelete = async (landscapeImages) => {
  try {
      // Display confirmation dialog
      
      const shouldDelete = window.confirm('Are you sure you want to delete this landscape photo?');
      
      if (!shouldDelete) {
          // User canceled the deletion
          return;
      }
      setLoading(true)
      // Assuming you have access to auth.token here
      const deleteLandscape = await deleteSingleLandscape(slug, landscapeImages, auth?.token);

      if (deleteLandscape && deleteLandscape.message) {
          // Show success toast
          loadSinglePhoto()
          setLandscapePhotoVisible(false)
          toast.success(deleteLandscape.message);
          setLoading(false)
      } else {
          // Show error toast
          toast.error('Failed to delete landscape photo');
          setLoading(false)
      }

  } catch (error) {
      console.error('Error deleting landscape photo:', error);
      // Show error toast
      toast.error('Internal Server Error');
      setLoading(false)
  }
};

const handleImageDelete =  async (i) => {
    
  try {
    // Display confirmation dialog
    setLoading(true)
    const shouldDelete = window.confirm('Are you sure you want to delete this landscape photo?');
    
    if (!shouldDelete) {
        // User canceled the deletion
        return;
    }

    // Assuming you have access to auth.token here
    const deleteLandscape = await deleteSingleImage(slug, i, auth?.token);

    if (deleteLandscape && deleteLandscape.message) {
        // Show success toast
        loadSinglePhoto()
        setPhotoVisible(false)
        toast.success(deleteLandscape.message);
        setLoading(false)

    } else {
        // Show error toast
        toast.error('Failed to delete landscape photo');
        setLoading(false)
    }
} catch (error) {
    console.error('Error deleting landscape photo:', error);
    // Show error toast
    toast.error('Internal Server Error');
    setLoading(false)
}
};
  
  return (
    <>
    <MetaData title="Update Photo" />
      <div className='dashboard-container'>
        <div className="dashboard-navbar">
          <Navbar/>
        </div>
        <div className="dashboard-bottom">
          <div className="menu-container">
              <Menu/>
          </div>
          <div className="dashboard-items dashboard-photo-create">
            <div className="create-photo-dashboard">
              <div className="create-photo-title">
                <h1>Upload Photos</h1>
              </div>
              <div className="photo-requirement">
                
                
                <Button className='requirement-btn' onClick={() => setLandscapePhotoVisible(true)}>View landscape Photo</Button>
                <Button className='requirement-btn' onClick={() => setPhotoVisible(true)}>View Photo</Button>
              </div>
            </div>
          </div>  
        </div>

            <Modal
              title="Add Project Photo"
              className='image-container'
              centered
              open={landscapePhotoVisible}
              onCancel={() => setLandscapePhotoVisible(false)}
              footer={null}
            >
              <div className="uploading-photo">
              <div className="uploaded-photo">
              {landscapeImages && loading ? (<Loader/>) :(landscapeImages.map((i) => (
                  <div className="image-container" key={i?.Key}>
                    {
                     (
                        <>
                        <img src={i?.Location} alt="" />
                      <AiFillCloseCircle className='photo-close' onClick={() =>handleLandscapePhotoDelete(i)} />
                        </>)
                    }
                      
                  </div>
              ))) }
              </div>
              </div>
            </Modal>
            <Modal
              title="Add Project Photo"
              centered
              open={photoVisible}
              onCancel={() => setPhotoVisible(false)}
              footer={null}
            >
              <div className="uploading-photo">
              <div className="uploaded-photo">
              {images && loading ? (<Loader/>) :  images.map((i) => (
                  <div className="image-container" key={i?.Key}>
                    {
                     (
                        <>
                        <img src={i?.Location} alt="" />
                      <AiFillCloseCircle className='photo-close' onClick={() =>handleImageDelete(i)} />
                        </>)
                    }
                      
                  </div>
              ))}
              </div>

              </div>
            </Modal>
    </div>
    </>
  )
}

export default UpdateSinglePhoto