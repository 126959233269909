import React, { useEffect, useRef, useState } from 'react'
import { getAllVideoCategory, postVideo} from "../../action/video.js"
import Navbar from "../Navbar/Navbar.jsx";
import { toast } from "react-toastify";
import Loader from "../../component/Loader/Loader.jsx"
import { AiFillCloseCircle } from 'react-icons/ai';
import ReactPlayer from "react-player";
import './create-video.css'
import axios from "axios";
import { removeImage } from "../../action/photo.js";
import { uploadBannerPhoto } from "../../action/video.js";
import Menu from "../Menu/Menu.jsx";
import { Button, Input, Select, Tooltip ,Space, Card, Col, Row , Alert, Progress, Modal } from 'antd';
import { useSelector } from 'react-redux';
import MetaData from "../../MetaData";
import {jwtDecode} from 'jwt-decode';
const { Option } = Select;  




function CreateVideo({history}) {
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState("")
  const [bannerPhotoVisible, setBannerPhotoVisible] = useState(false);
  const [bannerImage, setBannerImage] = useState({})
  const { auth } = useSelector((state) => ({ ...state }));
  const [videoVisible, setVideoVisible] = useState(false);
  const fileInputRef = useRef(null);
  const fileVideoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false); 
  const [videoTitle, setVideoTitle] = useState("")
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [video, setVideo] = useState({})
  useEffect(() => {
    allCategory();
  }, []); 

  
  useEffect(() => {
    const isAuthInvalid = !auth
    if (!isAuthInvalid) {
      const decodedToken = jwtDecode(auth.token);
      const currentTime = Date.now() / 1000; // in seconds
  
      if (decodedToken.exp < currentTime) {
        history.push('/admin');
        return;
      }
    } else {
      history.push('/admin');
    }
  }, [auth]);
  


  const allCategory = async () => {
    try {
        const allCategories = await getAllVideoCategory();
        
        setCategories(allCategories)
        
        
    } catch (error) {
        console.error(error);
        // Handle errors here
    }
  };

  const handlePhotoButtonClick = () => {
   
    fileInputRef.current.click();
};
  
  const handleVideoButtonClick = () => {
   
    fileVideoRef.current.click();
  };

  const handleVideo = async (e) => {
    try {
        setIsLoading(true)
        const file = e.target.files[0];
    
     
     
        setUploading(true);
    
        const videoData = new FormData();
        videoData.append("video", file);
        // save progress bar and send video as form data to backend
        try {
          const { data } = await axios.post(
            `/api/project/upload-video/${selectedCategory}`,
            videoData,
            {
              onUploadProgress: (e) => {
                setProgress(Math.round((100 * e.loaded) / e.total));
              },
            }
          );
          console.log(data);
        setVideo(data)
        setUploading(false);
        toast("Video Uploaded");
        } catch (error) {
          toast.error(error)
        }
        setIsLoading(false)
        
        
      } catch (err) {
        console.log(err);
        setUploading(false);
        setIsLoading(false)
        toast("Video upload failed");
      }

    
}

const handleBannerImage = async (e) => {
  let files = e.target.files;
  console.log(files);

  try {
    setIsLoading(true)
    const response = await uploadBannerPhoto(files, selectedCategory, auth.token);

    // Assuming response.images is the array of uploaded images
    if (response.images && response.images.length > 0) {
      const firstImage = response.images[0];

      // Set the state with relevant information
      setBannerImage({
        Key: firstImage.Key,
        Location: firstImage.Location,
        Bucket: firstImage.Bucket,
        ETag: firstImage.ETag,
        ServerSideEncryption: firstImage.ServerSideEncryption,
        key: firstImage.key,
        // Add other properties as needed
      });
    }
    setIsLoading(false)
  } catch (error) {
    // Handle error if the upload fails
    console.error('Upload error:', error);
    setIsLoading(false)
  }
};

const handlePhotoDelete = () => async () => {
  try {
    // Call the removeImage action to delete the image on the backend
    setIsLoading(true)
    await removeImage(bannerImage);

    // Remove the deleted image from the state on the frontend
    setBannerImage({})
    setIsLoading(false)
  } catch (error) {
    console.error('Error deleting image:', error);
    // Handle error if the image deletion fails
    setIsLoading(false)
  }
};

const handleVideoRemove = async () => {
    try {
      setIsLoading(true)
        setUploading(true);
        const { data } = await axios.post(
          `/api/project/remove-video`,
          video
        );
        console.log(data);
        setVideo({});
        setUploading(false);
        setIsLoading(false)
        toast("Video Removed")
      } catch (err) {
        console.log(err);
        setUploading(false);
        setIsLoading(false)
        toast("Video remove failed");
      }
}
  
   

   

      const slug = selectedCategory

      const handleVideoSave = async () => {
        if (!selectedCategory) {
          toast.error('Please select a category');
          return;
        }
    
        if (!bannerImage || Object.keys(bannerImage).length === 0) {
          toast.error('Please upload a banner image');
          return;
        }
    
        if (!video || Object.keys(video).length === 0) {
          toast.error('Please upload a video');
          return;
        }
    
        if (!videoTitle) {
          toast.error('Please enter a video title');
          return;
        }
        try {
          const saveVideo = await postVideo(slug, bannerImage, video, videoTitle, auth?.token);
          console.log(saveVideo);
          setSelectedCategory("")
          setBannerImage({})
          setVideo({})
          setVideoTitle("")
          // Display success toast
          toast.success('Video saved successfully');
        } catch (error) {
          console.error(error);
      
          // Display error toast
          toast.error('Error saving video data');
        }
      };

  return (
    <>
    <MetaData title="Create Video" />
      <div className='dashboard-container'>
        <div className="dashboard-navbar">
          <Navbar/>
        </div>
        <div className="dashboard-bottom">
          <div className="menu-container">
              <Menu/>
          </div>
          <div className="dashboard-items dashboard-photo-create">
            <div className="create-photo-dashboard">
              <div className="create-photo-title">
                <h1>Upload Video</h1>
              </div>
              <div className="photo-requirement">
                <div className="requirement-tile crt-vid">
                  <div className="create-vid-category">
                    <label><span style={{color:"white"}}>Category</span></label>
                    <Select
                    style={{ width: 250 , background:"white"}}
                    placeholder="Select a category"
                    value={selectedCategory}
                    
                    onChange={(value) => setSelectedCategory(value)}
                    >
                    {categories?.map((c) => (
                      <Option key={c?._id} value={c?.slug}>
                        {c?.title}
                      </Option>
                    ))}
                    </Select>
                  </div>
                  <div className="create-vid-title">
                    <label><span style={{color:"white"}}>Title</span></label>
                    <Input className='photos-title-input' value={videoTitle} onChange={(e) => setVideoTitle(e.target.value)}/>
                  </div>
                </div>
                <Button className='requirement-btn' onClick={() => setBannerPhotoVisible(true)}> <span style={{color:"black"}}>Display Video Image</span></Button>
                <Button className='requirement-btn' onClick={() => setVideoVisible(true)}><span style={{color:"black"}}>Upload Video</span></Button>
                <Button className='requirement-btn' onClick={() => handleVideoSave()}><span style={{color:"black"}}>Save Video</span></Button>
              </div>
            </div>
          </div>  
        </div>
        <Modal
              title="Add Thumbnail photo"
              centered
              open={bannerPhotoVisible}
              onCancel={() => setBannerPhotoVisible(false)}
              footer={null}
            >
              {
                selectedCategory === "" || videoTitle === "" ? <h1 style={{color:"black"}} className='model-title'>Please give a video title and select a category to upload video!!</h1> : (
                  <div className="uploading-photo">
                  <div className="uploaded-photo">
                  {bannerImage  && isLoading ? (<Loader/>) :
                      <div className="image-container" >
                        {
                           (
                            <>
                            {bannerImage?.Location && <img src={bannerImage?.Location} alt="" />}
                          <AiFillCloseCircle className='photo-close' onClick={handlePhotoDelete()} />
                            </>)
                        }
                          
                      </div>
                 }
                  </div>
    
                    <div className="upload-button">
                    {Object.keys(bannerImage).length === 0 ? (<Button
                        style={{
                          padding: '10px 50px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        onClick={handlePhotoButtonClick}
                        disabled={isLoading}
                      >
                        <span style={{color:"black"}}>Upload Photo</span>
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleBannerImage}
                          accept="image/*"
                          style={{ display: 'none' }}
                        />
                      </Button>) : (<div></div>)}
                      
          
      
                      </div>
                  </div>
                )
              }
             
            </Modal>
        <Modal
              title="Add Project Video"
              centered
              open={videoVisible}
              onCancel={() => setVideoVisible(false)}
              footer={null}
            >
              {
                selectedCategory === "" || videoTitle === "" ? <h1 style={{color:"black"}} className='model-title'>Please give a video title and select a category to upload video!!</h1> : (
                  <div className="uploading-video">
                <div className="upload-video">
                {!uploading && video?.Location && (
                  <>
                  <ReactPlayer
                  url={video?.Location}
                  width="410px"
                  height="240px"
                  controls
                  />
                  <Tooltip title="Remove Video" >
                  <AiFillCloseCircle className='video-remove' onClick={handleVideoRemove}/>

                  </Tooltip></>
                )}
                   {progress > 0 && uploading &&(
                      <Progress
                        className="d-flex justify-content-center pt-2"
                        percent={progress}
                        steps={10}
                      />
                    )}
                </div>
                <div className="upload-video-button">
                    {
                        Object.keys(video).length === 0 ? (<Button style={{ padding: "10px 50px", display: "flex", alignItems: "center", justifyContent: "center" }} 
                        onClick={handleVideoButtonClick}  disabled={isLoading}>
                          <span style={{color:"black"}}>Upload Video</span>
                          <input
                              type="file"
                              ref={fileVideoRef}
                              onChange={handleVideo}
                              accept="video/*"
                              style={{ display: "none" }}
                          />
                          </Button>) : (<div>

                          </div>)
                    }
                  
                </div>
               </div>
                )}
               
            </Modal>
    </div>
    </>
  )
}

export default CreateVideo