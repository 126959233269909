import React from 'react'


function Demo1() {
  return (
    <div className='demo-model'>
       <section>
          <div className="containerrrr">
          <h3 className='dedicated-title'>For Photography or Videography Only</h3>
            <div className="accordion">
              <div className="accordion-item" id="question5">
                <a className="accordion-link" href="#question5">
                    <div className="flex">
                        <h3>PRO PACKAGE</h3>
                        <ul>
                        <li>#Graduation</li>
                        <li>#Picnic</li>
                        <li>#Birthday</li>
                        <li>#Festivals</li>
                        <li>#Exibitions</li>
                        </ul>
                    </div>
                  <i className="icon ion-md-arrow-forward"></i>
                  <i className="icon ion-md-arrow-down"></i>
                </a>
                <div className="answer">
                  <p>Rs- 8500/hour (for extended time Rs- 60/min)</p>
                </div>
                <hr/>
            </div>
              <div className="accordion-item" id="question6">
                <a className="accordion-link" href="#question6">
                    <div className="flex">
                        <h3>SEMI PACKAGE</h3>
                        <ul>
                        <li>#Graduation</li>
                        <li>#Picnic</li>
                        <li>#Birthday</li>
                        <li>#Festivals</li>
                        <li>#Exibitions</li>
                        </ul>
                    </div>
                  <i className="icon ion-md-arrow-forward"></i>
                  <i className="icon ion-md-arrow-down"></i>
                </a>
                <div className="answer">
                  <p>Rs- 6500/hour (for extended time Rs- 30/min)</p>
                </div>
                <hr/>
            </div>
              <div className="accordion-item" id="question7">
                <a className="accordion-link" href="#question7">
                    <div className="flex">
                        <h3>LIGHT PACKAGE</h3>
                        <ul>
                        <li>#Graduation</li>
                        <li>#Picnic</li>
                        <li>#Birthday</li>
                        <li>#Festivals</li>
                        <li>#Exibitions</li>
                        </ul>
                    </div>
                  <i className="icon ion-md-arrow-forward"></i>
                  <i className="icon ion-md-arrow-down"></i>
                </a>
                <div className="answer">
                  <p>Rs- 5000/hour (for extended time Rs- 15/min)</p>
                </div>
                <hr/>
            </div>
       
          </div>
          </div>
        </section>

      
    </div>
  )
}

export default Demo1