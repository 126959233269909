import React from 'react'
import "./demo.css"

function Demo() {
  return (
    <div className='demo-model'>
       <section>
          <div className="containerrrr">
            <h3 className='dedicated-title'>For Photography + Videography</h3>
            <div className="accordion">
              <div className="accordion-item" id="question1">
                <a className="accordion-link" href="#question1">
                  <div className="flex">
                    <h3>PRO PACKAGE</h3>
                    <ul>
                      <li>#Graduation</li>
                      <li>#Picnic</li>
                      <li>#Birthday</li>
                      <li>#Festivals</li>
                      <li>#Exibitions</li>
                    </ul>
                  </div>
                  <i className="icon ion-md-arrow-forward"></i>
                  <i className="icon ion-md-arrow-down"></i>
                </a>
                <div className="answer">
                  <p>Rs- 12000/hour (for extended time Rs- 60/min)</p>
                </div>
                <hr/>
            </div>
              <div className="accordion-item" id="question2">
                <a className="accordion-link" href="#question2">
                  <div className="flex">
                    <h3>SEMI PACKAGE</h3>
                    <ul>
                      <li>#Graduation</li>
                      <li>#Picnic</li>
                      <li>#Birthday</li>
                      <li>#Festivals</li>
                      <li>#Exibitions</li>
                    </ul>
                  </div>
                  <i className="icon ion-md-arrow-forward"></i>
                  <i className="icon ion-md-arrow-down"></i>
                </a>
                <div className="answer">
                  <p>Rs- 8000/hour (for extended time Rs- 30/min)</p>
                </div>
                <hr/>
            </div>
              <div className="accordion-item" id="question3">
                <a className="accordion-link" href="#question3">
                  <div className="flex">
                    <h3>LIGHT PACKAGE</h3>
                    <ul>
                      <li>#Graduation</li>
                      <li>#Picnic</li>
                      <li>#Birthday</li>
                      <li>#Festivals</li>
                      <li>#Exibitions</li>
                    </ul>
                  </div>
                  <i className="icon ion-md-arrow-forward"></i>
                  <i className="icon ion-md-arrow-down"></i>
                </a>
                <div className="answer">
                  <p>Rs- 6000/hour (for extended time Rs- 15/min)</p>
                </div>
                <hr/>
            </div>
          
          </div>
          </div>
        </section>

      
    </div>
  )
}

export default Demo