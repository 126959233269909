import React, { useEffect, useRef, useState } from 'react'
import {AiFillDelete} from "react-icons/ai"
import { toast } from "react-toastify";
import Navbar from "../Navbar/Navbar.jsx";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {submitVideoCategory , getAllVideoCategory , deleteVideoCategory} from "../../action/video.js"
import ReactPlayer from "react-player";
import { Button, Input, Select, Tooltip ,Space, Card, Col, Row , Alert, Progress, Modal } from 'antd';
import Menu from "../Menu/Menu.jsx";
import { AiFillCloseCircle } from 'react-icons/ai';
import MetaData from "../../MetaData";
import {jwtDecode} from 'jwt-decode';
import './videocategory.css'
import { useSelector } from 'react-redux';
const { Search } = Input;



function VideoCategory() {
    const history = useHistory();
    const [categoryChanged, setCategoryChanged] = useState("")
    const { auth } = useSelector((state) => ({ ...state }));
    const [categories, setCategories] = useState([])
    const [videoVisible, setVideoVisible] = useState(false);
    const fileVideoRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false); 
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [displayVideo, setDisplayVideo] = useState({})
    const title = categoryChanged

    console.log(categoryChanged)
    useEffect(() => {
      const isAuthInvalid = !auth
    
      if (!isAuthInvalid) {
        const decodedToken = jwtDecode(auth.token);
        const currentTime = Date.now() / 1000; // in seconds
    
        if (decodedToken.exp < currentTime) {
          history.push('/admin');
          return;
        }
      } else {
        history.push('/admin');
      }
    }, [auth]);
    
    
useEffect(() => {
    allCategory();
  }, []); 
  
  
    console.log(categories)

    const allCategory = async () => {
        try {
            const allCategories = await getAllVideoCategory();
            
            setCategories(allCategories)
            
            
        } catch (error) {
            console.error(error);
            // Handle errors here
        }
      };

    const handleVideoButtonClick = () => {
   
        fileVideoRef.current.click();
      };

      const handleCategoryTtile = (slug) =>{
        history.push(`/dashboard/video-category/${slug}`)
      }

    const handleSubmit = async () => {
        if (!title || Object.keys(displayVideo).length === 0) {
            toast.error("Both title and video are required");
            return;
        }
        try {
            const submitCategory = await submitVideoCategory(title, displayVideo, auth?.token);
            
            if (submitCategory?.message) {
                setCategoryChanged("")
                setDisplayVideo({})
                allCategory()
                toast.success(submitCategory.message);

            } else {
                toast.error("Category already exists");
            }
        } catch (error) {
            console.error("Error submitting category:", error);
            toast.error("An error occurred while submitting the category");
        }
    };
    

    const handleVideo = async (e) => {
        try {
            setIsLoading(true)
            const file = e.target.files[0];
        
         
         
            setUploading(true);
        
            const videoData = new FormData();
            videoData.append("video", file);
            // save progress bar and send video as form data to backend
            try {
              const { data } = await axios.post(
                `/api/project/upload-video/${categoryChanged}`,
                videoData,
                {
                  onUploadProgress: (e) => {
                    setProgress(Math.round((100 * e.loaded) / e.total));
                  },
                }
              );
              console.log(data);
            setDisplayVideo(data)
            setUploading(false);
            toast("Video Uploaded");
            } catch (error) {
              toast.error(error)
            }
            setIsLoading(false)
            
            
          } catch (err) {
            console.log(err);
            setUploading(false);
            setIsLoading(false)
            toast("Video upload failed");
          }

        
    }

    const handleCategoryDelete = async (id) => {
      try {
          const shouldDelete = window.confirm('Are you sure you want to delete this video category?');
  
          if (!shouldDelete) {
              return; // Do nothing if the user cancels the deletion
          }
  
          // Assuming you have access to auth.token here
          const deleteCategory = await deleteVideoCategory(id, auth?.token);
  
          if (deleteCategory && deleteCategory.message) {
              // Show success toast
              toast.success(deleteCategory.message);
              allCategory();
          } else {
              // Show error toast
              toast.error('Failed to delete video category');
          }
      } catch (error) {
          console.error('Error deleting video category:', error);
          // Show error toast
          toast.error('Internal Server Error');
      }
  };
    
    const handleVideoRemove = async () => {
        try {
            setUploading(true);
            const { data } = await axios.post(
              `/api/project/remove-video`,
              displayVideo
            );
            console.log(data);
            setDisplayVideo({});
            setUploading(false);
            toast("Video Removed")
          } catch (err) {
            console.log(err);
            setUploading(false);
            toast("Video remove failed");
          }
    }
  return (
    <>
    <MetaData title="Video Category" />
    <div className='dashboard-container'>
        <div className="dashboard-navbar">
            <Navbar/>
        </div>
        <div className="dashboard-bottom">
          <div className="menu-container">
            <Menu/>
          </div>
          <div className="dashboard-items video-category">
                <Space.Compact style={{ width: '100%' }}>
                    <Input placeholder='Create project category' value={categoryChanged} onChange={(e) => setCategoryChanged(e.target.value)}/>
                    <Button type="primary" onClick={handleSubmit}>Submit</Button>
                </Space.Compact>
                <Button style={{ width: '100%' , color:"black" }}  onClick={() => setVideoVisible(true)}> <span style={{color:"black"}}>Upload Display Video</span> </Button>
                <div className="dashboard-cat-list">
                    <Row className='dashboard-row' gutter={16}>
                    {
                            categories?.map((category) => (
                                <Col span={7} key={category?._id} className='dash-cat-box'>
                                    <Card title={
                                    <div className='dashboard-cat-heading' >
                                        <span onClick={() => handleCategoryTtile(category.slug)} style={{cursor:"pointer" , textTransform:"capitalize"}}>{category.title}</span>
                                        <AiFillDelete style={{ marginLeft: '8px', cursor: 'pointer' , color:"#720808b7"}} 
                                        onClick={() =>handleCategoryDelete(category?._id)}
                                        />
                                    </div>
                                    } key={category?._id} bordered={false}>
                                    Total {category?.project?.length} Videos
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
          </div>
        </div>
        <Modal
              title="Add Project Video"
              centered
              open={videoVisible}
              onCancel={() => setVideoVisible(false)}
              footer={null}
            > 
            {
              categoryChanged.trim() === "" ? (<h1 style={{textAlign:"center"}}>Please Enter Category First</h1>) : ( 
                <div className="uploading-video">
                <div className="upload-video">
                {!uploading && displayVideo?.Location && (
                  <>
                  <ReactPlayer
                  url={displayVideo?.Location}
                  width="410px"
                  height="240px"
                  controls
                  />
                  <Tooltip title="Remove Video" >
                  <AiFillCloseCircle className='video-remove' onClick={handleVideoRemove}/>

                  </Tooltip></>
                )}
                   {progress > 0 && uploading &&(
                      <Progress
                        className="d-flex justify-content-center pt-2"
                        percent={progress}
                        steps={10}
                      />
                    )}
                </div>
                <div className="upload-video-button">
                    {
                        Object.keys(displayVideo).length === 0 ? (<Button style={{ padding: "10px 50px", display: "flex", alignItems: "center", justifyContent: "center" }} 
                        onClick={handleVideoButtonClick}  disabled={isLoading}>
                           <p style={{color:"black"}}>Upload Video</p>
                          <input
                              type="file"
                              ref={fileVideoRef}
                              onChange={handleVideo}
                              accept="video/*"
                              style={{ display: "none" }}
                          />
                          </Button>) : (<div>

                          </div>)
                    }
                  
                </div>
               </div>
              )
            }
            
            </Modal>
    </div>
    </>
  )
}

export default VideoCategory