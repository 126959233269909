import React, { useEffect, useRef, useState } from 'react'
import { getAllVideoCategory, getSingleVideoCategory} from "../../action/video.js"
// import CustomVideoPlayer  from '../CustomVideoPlayer/CustomVideoPlayer.jsx'
import { useParams } from 'react-router-dom';
import DP1 from '../../assests/wedding/0078.jpg'
import { FaArrowDownLong } from "react-icons/fa6";
import MetaData from "../../MetaData";
import DP2 from '../../assests/wedding/081.jpg'
import DP3 from '../../assests/wedding/165.jpg'
import DP4 from '../../assests/wedding/231.jpg'
import DP5 from '../../assests/wedding/JA205518.jpg'
import { FaPlay } from "react-icons/fa";
import DP6 from '../../assests/wedding/Legacy+Castle+642.jpg'
import { motion } from "framer-motion";
import ReactPlayer from "react-player";
import './video.css'
import { Modal } from 'antd'

const image = [
  DP1,
  DP2,
  DP3,
  DP4,
  DP5,
  DP6,
  
];

function Videos() {
  const [categories, setCategories] = useState([])
  const [singleCategories, setSingleCategories] = useState([])
  const { slug } = useParams();
  const [videoTitle, setVideoTitle] = useState("")
  const [activeCategory, setActiveCategory] = useState(0);
  const [video, setVideo] = useState("")
  const [videoVisible, setVideoVisible] = useState(false);
  const [project, setProject] = useState([])
  const videoRef = useRef(null);
  console.log(slug)
  useEffect(() => {
    allCategory();
    singleCategory()
  }, []); 
  console.log(categories[activeCategory]?.displayVideo?.Location)
  console.log(categories[activeCategory]?.project)
  console.log(categories)
  const allCategory = async () => {
    try {
        const allCategories = await getAllVideoCategory();
        
        setCategories(allCategories)
        
        
    } catch (error) {
        console.error(error);
        // Handle errors here
    }
  };

  const singleCategory = async () => {
    try {
      const singleCategories = await getSingleVideoCategory(slug);
      
      setSingleCategories(singleCategories)
      
      
  } catch (error) {
      console.error(error);
      // Handle errors here
  }
  }
  console.log(singleCategories)
  console.log(singleCategories.displayVideo)

  const handleCancel = () => {
    setVideoVisible(false);
    // Pause the video when the modal is closed
    if (videoRef.current && videoRef.current.getInternalPlayer) {
        const player = videoRef.current.getInternalPlayer();
        if (player && player.pause) {
            player.pause();
        }
    }
};

  const handleCategoryClick = (e, index) => {
    e.preventDefault();
    setActiveCategory(index);
    // setIsCategoryClicked(true);
  };
  return (
   <>
   <MetaData title="Videos" />
    <div className='video-container'>
            <div className="project-categories video-page">
            
              <div className='display-video'>
                            <video 
                      src={singleCategories?.displayVideo?.Location} 
                      autoPlay
                      muted
                      loop
                      controls
                  >
                     
                  </video>
              {/* <CustomVideoPlayer url={singleCategories?.displayVideo?.Location}/> */}
              {/* <ReactPlayer
                  url={singleCategories?.displayVideo?.Location}
                  width="100%"
                  playing={true}
                  loop={true}
                  muted={true}
                  className="react-player"
                  height="100%"
                  controls
               
                  /> */}
              </div>
            </div>
            <div className='video-title-and-arrow'>
            {singleCategories?.project && singleCategories?.project?.length > 0 && (
              <>
                <h1 className='video-cat-model-title'>{slug} Videos </h1>
                <FaArrowDownLong className='project-display-model-arrow' style={{ fontSize:"6vh" , color:"white"}}/>
              </>
            ) }
          
            </div>
         
        <div className="individual-videos">
        <div className="project-display video-part-display">
            {singleCategories?.project && singleCategories?.project?.length > 0 && 
            singleCategories?.project.slice().reverse().map((i, index) => (
                        <div className={`cat-${index + 1} cat-links video-div`} key={index}  style={{cursor : "pointer"}} 
                        onClick={() => {setVideoVisible(true) ; setVideo(i?.video?.Location) ; setVideoTitle(i?.videoTitle)}}>
                            <img src={i?.bannerImage?.Location} className='project-display-wedding' alt="Wedding" />
                            <h1 className='each-video-title'>{i?.videoTitle}</h1>
                            <div className="play-overlay">
                              <FaPlay className='video-play' />
                            </div>
                            
                        </div>
                    ))}
            </div>
        </div>
        <Modal
              title={videoTitle}
              playing={true}
              centered
              open={videoVisible}
              onCancel={handleCancel}
              footer={null}
            >
               <div className="uploading-video video-part-model">
                <div className="upload-video">
              
                  <>
                  <ReactPlayer
                  ref={videoRef}
                  url={video}
                  width="410px"
                  height="240px"
                  controls
                  />
                  </>
        
                </div>
               
               </div>
            </Modal>
    </div>
   </>
  )
}

export default Videos