import React, { useEffect, useRef, useState } from 'react'
import { getAllVideoCategory, postVideo , getSingleVideoCategory , updateVideoCategory, getSinglePhotoCategory, updatePhotoCategory} from "../../action/video.js"
import Navbar from "../Navbar/Navbar.jsx";
import { useParams } from 'react-router-dom';
import ReactPlayer from "react-player";
import { Button, Input, Select, Tooltip ,Space, Card, Col, Row , Alert, Progress, Modal } from 'antd';
import Menu from "../Menu/Menu.jsx";
import { toast } from "react-toastify";
import { Checkbox } from 'antd';
import { AiFillCloseCircle } from 'react-icons/ai';
import axios from "axios";
import "./updatePhotoTitle.css"
import { useHistory } from "react-router-dom";
import MetaData from "../../MetaData";
import { useSelector } from 'react-redux';
import {jwtDecode} from 'jwt-decode';
const { Option } = Select;  



function UpdateVideoTitle() {
  const [title, setTitle] = useState("")
  const [pin, setPin] = useState(false)
  const history = useHistory();

  const { auth } = useSelector((state) => ({ ...state }));

  const { slug } = useParams();

  useEffect(() => {
    loadSingleVideoCategory()
  }, [])

  
  useEffect(() => {
    const isAuthInvalid = !auth
  
    if (!isAuthInvalid) {
      const decodedToken = jwtDecode(auth.token);
      const currentTime = Date.now() / 1000; // in seconds
  
      if (decodedToken.exp < currentTime) {
        history.push('/admin');
        return;
      }
    } else {
      history.push('/admin');
    }
  }, [auth]);
  
  

  const loadSingleVideoCategory = async() =>{
    const singlePhotoCategory = await getSinglePhotoCategory(slug)
    console.log(singlePhotoCategory)
    setPin(singlePhotoCategory.pin)
    setTitle(singlePhotoCategory.title)
  }
  
console.log(pin)
  const handlePhotoSave = async () => {

 
  
    if (!title) {
      toast.error('Please enter a photo title');
      return;
    }
    try {
        // Call your updateVideoCategory function
        const result = await updatePhotoCategory(slug, title, pin, auth?.token);
  
        // Check if the update was successful
        if (result && result.message) {
            
            toast.success(result.message);
            history.push("/dashboard/photo-category")
        } else {
            // Show error toast
            toast.error('Failed to update photo category');
        }
    } catch (error) {
        console.error('Error updating photo category:', error);
        // Show error toast
        toast.error('Internal Server Error');
    }
  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setPin(e.target.checked)
  };
  



  return (
    <>
    <MetaData title="Update Photo" />
    <div className='dashboard-container'>
        <div className="dashboard-navbar">
          <Navbar/>
        </div>
        <div className="dashboard-bottom">
          <div className="menu-container">
              <Menu/>
          </div>
          <div className="dashboard-items createVid">
          <div className="create-photo-dashboard">
              <div className="create-photo-title">
                <h1>Upload Photo Category</h1>
              </div>
              <div className="photo-requirement">
                <div className="requirement-tile crt-vid">
                  
                  <div className="create-vid-title">
                    <label > <p className='dashboard-model-title'  style={{style:"white"}}>Title</p> </label>
                    <Input className='photos-title-input' value={title} onChange={(e) => setTitle(e.target.value)}/>

                  </div>
                </div>
                
                <Checkbox checked={pin} onChange={onChange}><p className='dashboard-model-title' style={{style:"white"}}>Pin</p> </Checkbox>
                <Button className='requirement-btn' onClick={() => handlePhotoSave()}>Save Changes</Button>
              </div>
            </div>
          </div>
        </div>
      
    </div>
    </>
  )
}

export default UpdateVideoTitle