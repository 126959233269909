import React from "react";
import Helmet from "react-helmet";
import logo from '../src/imgs/logo1.svg'

const MetaData = ({ title }) => {
  return (
    <Helmet>
      <link rel="icon" href={logo} />
      <title>{title}</title>
      <meta name="description" content="A professional photography and videography studio capturing your special moments with creativity and passion." />
      <meta name="keywords" content="Photo studio, Videography,Photo studio Nepal, Photo Studio Lalitpur ,Studio, Professional Photography, Creative Videography, Photo Studio, Video Studio, Image Tech Studio" />
      <meta name="robots" content="index,follow" />
    </Helmet>
  );
};

export default MetaData;