import React, { useEffect, useState } from "react";
import { useLocation  ,Link} from "react-router-dom"; // If you're using React Router




import "./paymentsuccess.css"
import { createBooking } from "../../action/book";
import { AddNotification } from "../../action/notification";

const PaymentSuccess = () => {
  const location = useLocation(); // React Router hook to get the current location
  const [paymentIntent, setPaymentIntent] = useState({});
  

  useEffect(() => {
    const fetchData = async () => {
      // Extract relevant information from the query parameters
      const searchParams = new URLSearchParams(location.search);
      console.log(searchParams.get("status") === "Completed");
      const pidx = searchParams.get("pidx");
      const transaction_id = searchParams.get("transaction_id");
      const amount = searchParams.get("amount");
      const mobile = searchParams.get("mobile");
      const purchase_order_id = searchParams.get("purchase_order_id");
      const purchase_order_name = searchParams.get("purchase_order_name");
  
      const paymentInfo = {
        pidx,
        transaction_id,
        amount,
        mobile,
        purchase_order_id,
        purchase_order_name,
      };

      const amountInCents = parseInt(amount);
      const amountInRupees = amountInCents / 100;
  
      let info;
      if (searchParams.get("status") === "Completed") {
        info = JSON.parse(window.localStorage.getItem("formData"));
        if(info){
            await createBooking(info);
        await AddNotification({
          title: "BOOKING MADE!!!!",
          message: `${info.name} has booked for Rs ${amountInRupees}. You can call him on this number ${info.phone}`,
          read: false,
        });
        }
        window.localStorage.removeItem("formData");
        
      }
  
      setPaymentIntent(paymentInfo);
    };
  
    fetchData();
  }, [location.search]);

  useEffect(() => {
    // Log the updated state
    console.log(paymentIntent);
  }, [paymentIntent]);



  return (
    <div className="payment-successfull">
      
        <p >
          Payment Successful!!
        </p>
          <Link to="/booking">Return Back</Link>
    </div>
  );
};

export default PaymentSuccess;
