import React, { useRef, useState } from 'react'
import { Modal } from 'antd'
import event1 from '../../imgs/events/3.jpg'
import event2 from '../../imgs/events/1.jpg'
import event3 from '../../imgs/events/2.jpg'
import ReactPlayer from "react-player";
import event4 from '../../imgs/events/5.jpg'
import event5 from '../../imgs/events/4.jpg'
import videoservice from '../../assests/vid.mp4'
import { FaArrowRightLong } from "react-icons/fa6";
import Demo from '../Demo/Demo'
import Demo1 from './Demo1.jsx'

import MetaData from "../../MetaData";

function Services() {
  const [video, setVideo] = useState("")
  const [videoVisible, setVideoVisible] = useState(false);
  const [videoTitle, setVideoTitle] = useState("")
  const videoRef = useRef(null);

  const handleCancel = () => {
    setVideoVisible(false);
    // Pause the video when the modal is closed
    if (videoRef.current && videoRef.current.getInternalPlayer) {
        const player = videoRef.current.getInternalPlayer();
        if (player && player.pause) {
            player.pause();
        }
    }
};
  return (
    <>
    <MetaData title="Services" />
    <div className='service-page-model'>
        <div className="service-model-top">
            <h3>Pricing Details</h3>
            <h1>Let's Plan your <span>Events</span></h1>
            <div className="service-model-shapes">
              <figure className="wave service">
                <img src={event1} alt="rajni"></img>
                
              </figure>
           
            

              <figure className="wave service">
                <img src={event2} alt="bean"></img>
            
              </figure>
            </div>
        </div>
        <div className="service-model-bottom">
              <div className="containery">
                <div className="cardy">
                  <img src={event3}/>
                  <div className="pricing-details">
                      
                      <h2>Details (Pro Package)</h2>
                        <ul>
                          <li>Rate - Rs 50000</li>
                          <li>14 Hours of Event Coverage (for extexted time - Rs 2000/hr)</li>
                          <li>Engagement Session & Group Photo Session</li>
                          <li>2 Photographer and 2 Videographer (with full lighting system)</li>
                          <li>Unlimited Professionally Edited Photos (full event video + 1 reel)</li>
                          <li>Custom Online Galary (if permitted)</li>
                          <li>300 Photos with Photo Album and Storage Facility (Pendrive , hardisk..)</li>
                          <li>12 x 18 Size Photo with Frame </li>
                        </ul>
                    </div>

                    <div className='demo-video-section'>
                      <h2>Package Video Editing Showcase <FaArrowRightLong className='demo-video-arrow'/></h2>
                      <a className="glightbox_video" onClick={() => {setVideoVisible(true) ; setVideo(videoservice) ; setVideoTitle("Pro Package")}}> 
                      <svg width="80" height="80" viewBox="0 0 131 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path className="inner-circle" d="M65 21C40.1488 21 20 41.1488 20 66C20 90.8512 40.1488 111 65 111C89.8512 111 110 90.8512 110 66C110 41.1488 89.8512 21 65 21Z" fill="white"></path>
                          <circle className="outer_circle" cx="65.5" cy="65.5" r="64" stroke="white"></circle>
                          <path className="play" fill-rule="evenodd" clip-rule="evenodd" d="M60 76V57L77 66.7774L60 76Z" fill="#BF2428"></path>
                      </svg>
                      </a>
                    </div>
                  <div className="card__heady">Pro Package </div>
                </div>
                <div className="cardy">
                  <img src={event4}/>
                  <div className="pricing-details">
                  <h2>Details (Medium Package)</h2>
                        <ul>
                          <li>Rate - Rs 35000</li>
                          <li>10 Hours of Event Coverage (for extexted time - Rs 1500/hr)</li>
                          <li>Engagement Session & Group Photo Session</li>
                          <li>2 Photographer and 1 Videographer (with basic lighting system)</li>
                          <li>Unlimited Professionally Edited Photos (full event video + 1 reel)</li>
                          <li>Custom Online Galary (if permitted)</li>
                          <li>150 Photos with Photo Album and Storage Facility (Pendrive , hardisk..)</li>
                          <li>10 x 15 Size Photo with Frame </li>
                        </ul>
                    </div>
                   
                    <div className='demo-video-section'>
                      <h2>Package Video Editing Showcase <FaArrowRightLong className='demo-video-arrow'/></h2>
                      <a className="glightbox_video"  onClick={() => {setVideoVisible(true) ; setVideo(videoservice) ; setVideoTitle("Medium Package")}}> 
                      <svg width="80" height="80" viewBox="0 0 131 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path className="inner-circle" d="M65 21C40.1488 21 20 41.1488 20 66C20 90.8512 40.1488 111 65 111C89.8512 111 110 90.8512 110 66C110 41.1488 89.8512 21 65 21Z" fill="white"></path>
                          <circle className="outer_circle" cx="65.5" cy="65.5" r="64" stroke="white"></circle>
                          <path className="play" fill-rule="evenodd" clip-rule="evenodd" d="M60 76V57L77 66.7774L60 76Z" fill="#BF2428"></path>
                      </svg>
                      </a>
                    </div>
                  <div className="card__heady">Semi Package 
                  </div>
                </div>
                <div className="cardy">
                  <img src={event5}/>
                  <div className="pricing-details">
                  <h2>Details (Light Package)</h2>
                        <ul>
                          <li>Rate - Rs 25000</li>
                          <li>8 Hours of Event Coverage (for extexted time - Rs 1000/hr)</li>
                          <li>Engagement Session</li>
                          <li>1 Photographer and 1 Videographer</li>
                          <li>Unlimited Professionally Edited Photos (full event video + 1 reel)</li>
                          <li>Custom Online Galary (if permitted)</li>
                          <li>100 Photos with Photo Album and Storage Facility (Pendrive , hardisk..)</li>
                          <li>8 x 12 Size Photo with Frame </li>
                        </ul>
                    </div>
                  
                    <div className='demo-video-section'>
                      <h2>Package Video Editing Showcase <FaArrowRightLong className='demo-video-arrow'/></h2>
                      <a className="glightbox_video"  onClick={() => {setVideoVisible(true) ; setVideo(videoservice) ; setVideoTitle("Light Package")}}> 
                      <svg width="80" height="80" viewBox="0 0 131 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path className="inner-circle" d="M65 21C40.1488 21 20 41.1488 20 66C20 90.8512 40.1488 111 65 111C89.8512 111 110 90.8512 110 66C110 41.1488 89.8512 21 65 21Z" fill="white"></path>
                          <circle className="outer_circle" cx="65.5" cy="65.5" r="64" stroke="white"></circle>
                          <path className="play" fill-rule="evenodd" clip-rule="evenodd" d="M60 76V57L77 66.7774L60 76Z" fill="#BF2428"></path>
                      </svg>
                      </a>
                    </div>
                  <div className="card__heady">Light Package </div>
                </div>
              
              </div>
        </div>
        <div className="service-dedicated-hours">
          <h2><span>Dedicated</span> Hours</h2>
          <div className="dedicated-hour-div">
                <Demo/>
                <Demo1/>
          </div>
        </div>
        <div className="service-saying-div">
   
          <p>"𝑷𝒉𝒐𝒕𝒐𝒈𝒓𝒂𝒑𝒉𝒚 𝒊𝒔 𝒂  𝒘𝒂𝒚 𝒐𝒇 𝒇𝒆𝒆𝒍𝒊𝒏𝒈, 𝒐𝒇 𝒕𝒐𝒖𝒄𝒉𝒊𝒏𝒈, 𝒐𝒇 𝒍𝒐𝒗𝒊𝒏𝒈. 𝑾𝒉𝒂𝒕 𝒚𝒐𝒖 𝒉𝒂𝒗𝒆 𝒄𝒂𝒖𝒈𝒉𝒕 𝒐𝒏 𝒇𝒊𝒍𝒎 𝒊𝒔 𝒄𝒂𝒑𝒕𝒖𝒓𝒆𝒅 𝒇𝒐𝒓𝒆𝒗𝒆𝒓…𝒊𝒕  
            𝒓𝒆𝒎𝒆𝒎𝒃𝒆𝒓𝒔 𝒍𝒊𝒕𝒕𝒍𝒆 𝒕𝒉𝒊𝒏𝒈𝒔, 𝒍𝒐𝒏𝒈 𝒂𝒇𝒕𝒆𝒓 𝒚𝒐𝒖 𝒉𝒂𝒗𝒆 𝒇𝒐𝒓𝒈𝒐𝒕𝒕𝒆𝒏 𝒆𝒗𝒆𝒓𝒚𝒕𝒉𝒊𝒏𝒈."</p>
            <h3>-𝑨𝒂𝒓𝒐𝒏 𝑺𝒊𝒔𝒌𝒊𝒅</h3>
        </div>
        <Modal
              title={videoTitle}
              playing={true}
              centered
              open={videoVisible}
              onCancel={handleCancel}
              footer={null}
            >
               <div className="uploading-video video-part-model">
                <div className="upload-video">
              
                  <>
                  <ReactPlayer
                  ref={videoRef}
                  url={video}
                  width="410px"
                  height="240px"
                  controls
                  />
                  </>
        
                </div>
               
               </div>
            </Modal>
    </div>
    
    </>
  )
}

export default Services