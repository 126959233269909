import React from 'react'
import wed1 from '../../imgs/creative/1.jpg'
import wed2 from '../../imgs/creative/2.jpg'
import wed3 from '../../imgs/creative/3.jpg'
import wed4 from '../../imgs/creative/4.jpg'
import creative1 from '../../imgs/creative1/1.jpg'
import creative2 from '../../imgs/creative1/2.jpg'
import creative3 from '../../imgs/creative1/3.jpg'
import creative4 from '../../imgs/creative1/4.jpg'
import creative5 from '../../imgs/creative1/5.jpg'

import MetaData from "../../MetaData";

function Services() {
  return (
    <>
    <MetaData title="Services" />
    <div className='service-page-model'>
        <div className="service-model-top">
            <h3>Pricing Details</h3>
            <h1><span>Creative</span> Photography</h1>
            <div className="service-model-shapes">
              <figure className="wave service">
                <img src={creative1} alt="rajni"></img>
                
              </figure>
           
            

              <figure className="wave service">
                <img src={creative2} alt="bean"></img>
            
              </figure>
            </div>
        </div>
        <div className="service-model-bottom">
              <div className="containery">
                <div className="cardy">
                  <img src={creative3}/>
                    <div className="pricing-details">
                      <h2>Details Will Be Added Soon.............</h2>
                      
                    </div>
                  <div className="card__heady">Wedding Package 1</div>
                </div>
                <div className="cardy">
                  <img src={creative4}/>
                  <div className="pricing-details">
                  <h2>Details Will Be Added Soon...........</h2>
                    </div>
                  <div className="card__heady">Wedding Package 2
                  </div>
                </div>
                <div className="cardy">
                  <img src={creative5}/>
                  <div className="pricing-details">
                  <h2>Details Will Be Added Soon.......</h2>
                    </div>
                  <div className="card__heady">Wedding Package 3</div>
                </div>
              
              </div>
        </div>
        <div className="service-saying-div">
   
          <p>"𝑷𝒉𝒐𝒕𝒐𝒈𝒓𝒂𝒑𝒉𝒚 𝒊𝒔 𝒂  𝒘𝒂𝒚 𝒐𝒇 𝒇𝒆𝒆𝒍𝒊𝒏𝒈, 𝒐𝒇 𝒕𝒐𝒖𝒄𝒉𝒊𝒏𝒈, 𝒐𝒇 𝒍𝒐𝒗𝒊𝒏𝒈. 𝑾𝒉𝒂𝒕 𝒚𝒐𝒖 𝒉𝒂𝒗𝒆 𝒄𝒂𝒖𝒈𝒉𝒕 𝒐𝒏 𝒇𝒊𝒍𝒎 𝒊𝒔 𝒄𝒂𝒑𝒕𝒖𝒓𝒆𝒅 𝒇𝒐𝒓𝒆𝒗𝒆𝒓…𝒊𝒕  
            𝒓𝒆𝒎𝒆𝒎𝒃𝒆𝒓𝒔 𝒍𝒊𝒕𝒕𝒍𝒆 𝒕𝒉𝒊𝒏𝒈𝒔, 𝒍𝒐𝒏𝒈 𝒂𝒇𝒕𝒆𝒓 𝒚𝒐𝒖 𝒉𝒂𝒗𝒆 𝒇𝒐𝒓𝒈𝒐𝒕𝒕𝒆𝒏 𝒆𝒗𝒆𝒓𝒚𝒕𝒉𝒊𝒏𝒈."</p>
            <h3>-𝑨𝒂𝒓𝒐𝒏 𝑺𝒊𝒔𝒌𝒊𝒅</h3>
        </div>
    </div>
    
    </>
  )
}

export default Services