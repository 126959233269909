import React from 'react'
import TM1 from '../../assests/team/1.jpg'
import TM2 from '../../assests/team/2.jpg'
import TM3 from '../../assests/team/3.jpg'
import MetaData from "../../MetaData";
import './team.css'

function Team() {
  return (
    <>
    <div className='team-container'>
        <div className="booking-title">
            <div className="booking-line"></div>
            <h1>Our Team</h1>
        </div>
        <div className="team-members">
            <div className="single-member">
                <div className="photo-part">
                    <img src={TM1} alt="" />
                </div>
                <div className="member-desc">
                    <h1>SAMUEL CRUZE</h1>
                    <p className='member-role'>Owner</p>
                    <p>Seasoned photographer with 10 years of hands-on experience, 
                        dept at translating visions into captivating visuals through a unique blend of technical skill and artistic flair.</p>
                </div>
            </div>
            <div className="single-member">
            <div className="photo-part">
                    <img src={TM2} alt="" />
                </div>
                <div className="member-desc">
                    <h1>Sam</h1>
                    <p className='member-role'>Photographer</p>
                    <p>Emerging photographer with 2 years of experience, 
                        bringing fresh perspectives and a growing mastery of the craft to create compelling visual narratives.</p>
                </div>
            </div>
            <div className="single-member">
            <div className="photo-part">
                    <img src={TM3} alt="" />
                </div>
                <div className="member-desc">
                    <h1>Mark</h1>
                    <p className='member-role'>Photographer</p>
                    <p>Proin ultricies augue libero, faucibus elit elementum sed dolor felis, cursus non diam non, 
                        finibus feugiat dui, a facilisis urna a ex magna</p>
                </div>
            </div>
        </div>
    </div>
    </>
    
  )
}

export default Team