import React, { useEffect, useState } from 'react'
import { DateRange } from "react-date-range";
import { toast } from "react-toastify";
import MetaData from "../../MetaData";
import {createBooking , getAllBooking} from "../../action/book"
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import {  Tooltip, Button, Modal,  Select, Progress, Input  } from "antd";
import khalti from "../../assests/khalti.jpg"
import "./booking.css"
import { AddNotification } from '../../action/notification';
import axios from 'axios';

const { Option } = Select;

function Booking() {
    const [photoVisible, setPhotoVisible] = useState(false);
    const [bookDate, setBookDate] = useState({})
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [price, setPrice] = useState(0)
    const [address, setAddress] = useState("")
    const [markedDateRanges, setMarkedDateRanges] = useState([]);
    const [location, setLocation] = useState("")
    const [dates, setDates] = useState(
            {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
            },
    );
    console.log(dates)

    useEffect(() => {
      loadAllBookingDates()
    }, [])
    

    const loadAllBookingDates = async () => {
        try {
            const allBookingDates = await getAllBooking();
            console.log(allBookingDates);
    
            // Extract and map the date ranges to the format expected by DateRange
            const markedDateRanges = allBookingDates.map(booking => ({
                startDate: new Date(booking.startDate),
                endDate: new Date(booking.endDate),
                key: "markedRange", // Use a consistent key for marked ranges
                color: "red",
                disabled: true, // Set the color to red for marked ranges
            }));
            console.log(markedDateRanges)
    
            // Set the marked date ranges in state
            setMarkedDateRanges(markedDateRanges);
        } catch (error) {
            console.error(error);
        }
    };
    const checkDateAvailability = (selectedDates) => {
        const selectedStartDate = new Date(selectedDates.startDate);
        const selectedEndDate = new Date(selectedDates.endDate);

        // Check for overlap with each booked date range
        for (const bookedRange of markedDateRanges) {
            const bookedStartDate = new Date(bookedRange.startDate);
            const bookedEndDate = new Date(bookedRange.endDate);

            if (
                selectedStartDate <= bookedEndDate &&
                selectedEndDate >= bookedStartDate
            ) {
                // Date overlap found
                return false;
            }
        }

        // No overlap, date is available
        return true;
    }
        
    const handleBooking = async () => {
        try {
            // Check for date conflicts before creating a booking
            const isDateAvailable = checkDateAvailability(dates);

            if (isDateAvailable) {
                // If date is available, proceed with booking
                await createBooking({ name, email, phone, address, location, dates, price });
                await AddNotification({
                    title: "BOOKING MADE!!!!",
                    message: `${name} has booked for Rs ${price}. You can call him on this number ${phone}`,
                    
                    read: false,
                  } );
                setName("")
                setEmail("")
                setPhone("")
                setAddress("")
                setLocation("")
                setDates( {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: "selection",
                   
                    })
                setPrice("")
                setPhotoVisible(false)
                loadAllBookingDates()
                
                
                toast.success('Booking successful!');
            } else {
                // If date is not available, show error toast
                toast.error('Sorry, I am busy on the selected dates.');
            }
        } catch (error) {
            console.error(error);
        }
    }

    

    const handleBuy = async () => {

        try {
            const isDateAvailable = checkDateAvailability(dates);
        
            if (isDateAvailable) {
                if (!name || !email || !phone || !address  || price <= 0) {
                    toast.error('Please fill in all the required fields.');
                    return;
                  }
              
                  // Additional validation for phone number (you can customize this based on your requirements)
                  const phoneRegex = /^[0-9]{10}$/; // Assuming a 10-digit phone number
                  if (!phoneRegex.test(phone)) {
                    toast.error('Please enter a valid 10-digit phone number.');
                    return;
                  }
        
                  
        
                  const currentDate = new Date();
                  
                   
                  if (
                    dates.startDate.toDateString() === currentDate.toDateString() ||
                    dates.endDate.toDateString() === currentDate.toDateString()
                  ) {
                    toast.error('Please select a valid date range (not today\'s date).');
                    return;
                  }
              
                  // Additional validation for email (you can customize this based on your requirements)
                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  if (!emailRegex.test(email)) {
                    toast.error('Please enter a valid email address.');
                    return;
                  }
                try {
                    const formData = {
                        location,
                        dates,
                        name,
                        email,
                        phone,
                        price,
                        address,
                      };
                  
                      // Save the entire form data object to localStorage
                      localStorage.setItem('formData', JSON.stringify(formData));
                  const payload = {
                    return_url: "https://jenishshrestha2000.com.np/payment-success",
                    website_url: "https://jenishshrestha2000.com.np/",
                    amount: parseInt(price) * 100,
                    purchase_order_id: "123123123",
                    purchase_order_name: name,
                    customer_info: {
                      name: name,
                      email: email,
                      phone: phone,
                    },
                  };
              
                  const response = await axios.post(`/api/khalti-app`, payload);
                  console.log(response);
              
                  if (response) {
                    window.location.href = `${response?.data?.payment_url}`;
                  }
                } catch (error) {
                  console.error("Error:", error.response ? error.response.data : error.message);
              
                  if (error.response && error.response.data.error_key === 'validation_error') {
                    // Display a toast for validation error
                    toast.error('Phone number is invalid. Please enter a valid mobile or landline number.');
                  } else {
                    // Display a generic error toast for other errors
                    toast.error('Phone number is invalid. Please enter a valid mobile or landline number.');
                  }
                }
            }else{
                toast.error('Sorry, I am busy on the selected dates.');
            }
        } catch (error) {
            console.error(error);
        }

       
        
        
      };

    // Function to check if the selected date range overlaps with booked date ranges
   
  return (
    <div className='booking-container'>
        <div className="booking-title">
            <div className="booking-line"></div>
            <h1>Booking Details</h1>
        </div>
        <div className="booking-category">
            <div className="book-details">
                <div className="single-booking-title">
                    <h1>INDIVIDUAL</h1>
                    <p className='pro-pack'>PRO PACKAGE</p>
                    <span>STARTING FROM</span>
                    <h1>Rs 250</h1>
                </div>
                <div className="single-booking-details">
                    <p>1 hour on location</p>
                    <p>2 outfit changes</p>
                    <p>20 images</p>
                    <p>Lo-res images for web</p>
                    <p>Hi-res images on CD</p>
                </div>
                
                <div className="book-me-btn" onClick={() => { setPhotoVisible(true) ; setPrice(250)}}>
                    <h1>Book Me</h1>
                </div>
            </div>
            <div className="book-details">
            <div className="single-booking-title">
                    <h1>CORPORATE</h1>
                    <p className='pro-pack'>PRO PACKAGE</p>
                    <span>STARTING FROM</span>
                    <h1>Rs 550</h1>
                </div>
                <div className="single-booking-details">
                    <p>4 hours on location</p>
                    <p>4 hours on location</p>
                    <p>80 images</p>
                    <p>Lo-res images for web</p>
                    <p>Hi-res images on CD</p>
                </div>
                
                <div className="book-me-btn" onClick={() =>{ setPhotoVisible(true) ; setPrice(550)}}>
                    <h1>Book Me</h1>
                </div>
            </div>
            <div className="book-details">
            <div className="single-booking-title">
                    <h1>WEDDING</h1>
                    <p className='pro-pack'>PRO PACKAGE</p>
                    <span>STARTING FROM</span>
                    <h1>Rs 750</h1>
                </div>
                <div className="single-booking-details">
                    <p>All day on location</p>
                    <p>3 photographers</p>
                    <p>3 photographers</p>
                    <p>Lo-res images for web</p>
                    <p>Hi-res images on CD</p>
                </div>
                
                <div className="book-me-btn" onClick={() => { setPhotoVisible(true) ; setPrice(750)}}>
                    <h1>Book Me</h1>
                </div>
            </div>
            <div className="book-details">
            <div className="single-booking-title">
                    <h1>INDIVIDUAL</h1>
                    <p className='pro-pack'>STARTER PACKAGE</p>
                    <span>STARTING FROM</span>
                    <h1>Rs 200</h1>
                </div>
                <div className="single-booking-details">
                    <p>1 hour on location</p>
                    <p>2 outfit changes</p>
                    <p>20 images</p>
                    <p>Lo-res images for web</p>
                    <p>Hi-res images on CD</p>
                </div>
                
                <div className="book-me-btn" onClick={() => { setPhotoVisible(true) ; setPrice(200)}}>
                    <h1>Book Me</h1>
                </div>
            </div>
            <div className="book-details">
            <div className="single-booking-title">
                    <h1>CORPORATE</h1>
                    <p className='pro-pack'>STARTER PACKAGE</p>
                    <span>STARTING FROM</span>
                    <h1>Rs 300</h1>
                </div>
                <div className="single-booking-details">
                    <p>1 hour on location</p>
                    <p>2 outfit changes</p>
                    <p>20 images</p>
                    <p>Lo-res images for web</p>
                    <p>Hi-res images on CD</p>
                </div>
                
                <div className="book-me-btn" onClick={() => { setPhotoVisible(true) ; setPrice(300)}}>
                    <h1>Book Me</h1>
                </div>
            </div>
            <div className="book-details">
            <div className="single-booking-title">
                    <h1>WEDDING</h1>
                    <p className='pro-pack'>STARTER PACKAGE</p>
                    <span>STARTING FROM</span>
                    <h1>Rs 500</h1>
                </div>
                <div className="single-booking-details">
                    <p>1 hour on location</p>
                    <p>2 outfit changes</p>
                    <p>20 images</p>
                    <p>Lo-res images for web</p>
                    <p>Hi-res images on CD</p>
                </div>
                
                <div className="book-me-btn" onClick={() => { setPhotoVisible(true) ; setPrice(500)}}>
                    <h1>Book Me</h1>
                </div>
            </div>
        </div>
        <Modal
              title="Book Me"
              centered
              open={photoVisible}
              className='book-me-now'
              onCancel={() => setPhotoVisible(false)}
              footer={null}
            >
            <div className="book-me-from">
            <DateRange
                    editableDateInputs={true}
                    onChange={(item) => setDates(item.selection)}
                    moveRangeOnFirstSelection={false}
                    ranges={[dates, ...markedDateRanges]}
                    className="date"
                    
                    minDate={new Date()}
                  />
                <label>Fullname</label>
                <Input onChange={(e) => setName(e.target.value)} value={name}/>
                <label>Email</label>
                <Input type='email' onChange={(e) => setEmail(e.target.value)} value={email}/>
                <label>Phone</label>
                <Input type='tel' onChange={(e) => setPhone(e.target.value)} value={phone}/>
                <label>Address</label>  
                <Input onChange={(e) => setAddress(e.target.value)} value={address}/>
                <img src={khalti} className='khalti-logo' alt="" />
                <Button className='book-btn' onClick={() =>handleBuy()}><p>Pay With Khalti</p></Button>
            </div>
            </Modal>
    </div>
  )
}

export default Booking